import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Col } from "reactstrap";

import "./footer.css";

import FB from "../assets/svg/facebook.svg";
import TWITTER from "../assets/svg/twitter.svg";
import INSTAGRAM from "../assets/svg/instagram.svg";
import {connect} from "react-redux";

const FooterComponent = ({ toggleTerms, togglePrivacy, location }) => (
  <div className="footer-dark mt-5">
    <div className="container py-3">
      <Row>
        <Col className="pt-5 pb-4 d-flex justify-content-center align-items-center">
          <a
            href="https://www.facebook.com/thewinitapp/"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-4"
          >
            <img
              className="footer-social-icon"
              src={FB}
              alt="Follow WinIt on Facebook"
            />
          </a>
          <a
            href="https://twitter.com/winit_app?lang=en"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-4"
          >
            <img
              className="footer-social-icon"
              src={TWITTER}
              alt="Follow WinIt on Twitter"
            />
          </a>
          <a
            href="https://www.instagram.com/app_winit/"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-4"
          >
            <img
              className="footer-social-icon"
              src={INSTAGRAM}
              alt="Follow WinIt on Instagram"
            />
          </a>
        </Col>
      </Row>
      <Row>
        <Col className="affiliate text-center py-2 d-flex justify-content-center align-items-center">
          {location.domainCamelCase} is not affiliated with any city, county, or state government.
        </Col>
      </Row>
      <Row>
        <Col className="py-2 text-center d-flex justify-content-center align-items-center">
          © 2017 - {moment().year()} Winit LLC, 1216 Broadway. 2nd Fl. New York, NY 10001 | All Rights Reserved.
        </Col>
      </Row>
      <Row>
        <Col className="text-center pt-2 pb-5 d-flex justify-content-center align-items-center">
          Disclaimer: WinIt is not a law firm. Your parking advocate is not an
          attorney. Communications do not constitute legal advice.
        </Col>
      </Row>
      <Row>
        <Col className="text-center pb-5 d-flex justify-content-center align-items-center">
          <div className="footer-link" onClick={togglePrivacy}>
            Privacy Policy
          </div>
          <div className="footer-link" onClick={toggleTerms}>
            Terms of Service
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

const mapState = state => ({
  location: state.general.location
});

FooterComponent.propTypes = {
  togglePrivacy: PropTypes.func.isRequired,
  toggleTerms: PropTypes.func.isRequired
};

export default connect(mapState)(FooterComponent);
