import React from "react";
import classnames from "classnames";
import { Container, Row, Col } from "reactstrap";

import { SectionTitle } from "./index";

// SVG graphic items
import WINITCOMINGSOON from "../assets/svg/winItComingSoon.svg";
import CREDITCARDS from "../assets/svg/creditCards.svg";
import TICKETGUARDINGCOMINGSOON from "../assets/svg/ticketGuardianComingSoon.svg";

import "./whatWeOffer.css";
import {connect} from "react-redux";

const colSizeProps = {
  lg: 4,
  md: 4,
  sm: 12,
  className: "d-flex mt-5 flex-column align-items-center"
};

const miniColSizeProps = {
  lg: 4,
  md: 12,
  className: "d-flex flex-column align-items-center"
};

const WhatWeOfferRow = ({ colSizes, location }) => (
  <Row>
    <Col {...colSizes}>
      <div className="what-we-offer-img">
        <img src={CREDITCARDS} alt="Secure payment by credit card." />
      </div>
      <SectionTitle>PAYMENT</SectionTitle>
      <div className="what-we-offer-description">
        You can securely pay your tickets via our platform.
      </div>
    </Col>
    <Col {...colSizes}>
      <div className="what-we-offer-img">
        <img
          src={TICKETGUARDINGCOMINGSOON}
          alt={`Ticket Guardian coming soon to ${location.name}.`}
        />
      </div>
      <SectionTitle>TICKET GUARDIAN</SectionTitle>
      <div className="what-we-offer-description">
        We will monitor your plate and notify you when you have new tickets.
      </div>
    </Col>
    <Col {...colSizes}>
      <div className="what-we-offer-img">
        <img src={WINITCOMINGSOON} alt={`WinIt coming soon to ${location.name}.`} />
      </div>
      <SectionTitle>WINIT</SectionTitle>
      <div className="what-we-offer-description">
        Dispute your ticket with WinIt technology.
      </div>
    </Col>
  </Row>
);

const WhatWeOffer = ({ mini, location }) => {
  const colSizes = mini ? miniColSizeProps : colSizeProps;
  if (mini) return <WhatWeOfferRow colSizes={colSizes} location={location} />;
  return (
    <div
      className={classnames({
        "pt-5 mb-5": true,
        "d-flex flex-column justify-content-center align-items-center": true
      })}
      id="Services"
    >
      <h2>What We Offer</h2>
      <Container>
        <WhatWeOfferRow colSizes={colSizes} location={location}/>
      </Container>
    </div>
  );
};
WhatWeOffer.defaultProps = {
  mini: false
};

const mapState = state => ({
  location: state.general.location
});

export default connect(mapState)(WhatWeOffer);
