import React from "react";
import { Row, Col } from "reactstrap";

import { SUPPORT_EMAILS, emailLinkGenerator } from "../helpers";
import {connect} from "react-redux";

const ContactInfoComponent = ({ location }) => (
  <div className="w-120 p-2">
    <Row>
      <Col>
        Email <a href={emailLinkGenerator(SUPPORT_EMAILS[location.name.toLowerCase()])}>{SUPPORT_EMAILS[location.name.toLowerCase()]}</a>
      </Col>
    </Row>
  </div>
);

const mapState = state => ({
  location: state.general.location
});

export default connect(mapState)(ContactInfoComponent);
