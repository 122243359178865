// eslint-disable-next-line
const calculateCreditCardFees = (totalSelectedFines, creditCardFeePercentage) =>
  totalSelectedFines * creditCardFeePercentage;

const calculateTotalFines = selectFines =>
  Object.keys(selectFines).reduce(
    (total, id) => total + selectFines[id].amount,
    0
  );

export { calculateCreditCardFees, calculateTotalFines };
