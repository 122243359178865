import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import { Header, Footer } from "./components";

import { RenderModals } from "./containers";

import {
  getWindowWidth,
  resetEverything,
  toggleMobileMenu,
  toggleModalVisibility
} from "./store";

class TemplateComponent extends Component {
  componentDidMount() {
    // eslint-disable-next-line
    window.addEventListener('resize', this.props.updateWidth)
  }
  componentWillUnmount() {
    // eslint-disable-next-line
    window.removeEventListener('resize', this.props.updateWidth)
  }

  render() {
    return (
      <Container fluid className="d-flex flex-column p-0 m-0 h-100">
        <Header
          location={this.props.location}
          isMobile={this.props.isMobile}
          toggle={this.props.toggleMenu}
          toggleServices={this.props.toggleServices}
          toggleContact={this.props.toggleContact}
          showMobileMenu={this.props.showMobileMenu}
          goHome={this.props.goHome}
        />
        <RenderModals />
        <div className="flex-grow-1">{this.props.children}</div>
        <Footer
          toggleTerms={this.props.toggleTerms}
          togglePrivacy={this.props.togglePrivacy}
        />
      </Container>
    );
  }
}

const mapState = state => ({
  showMobileMenu: state.general.showMenu,
  isMobile: state.general.isMobile,
  location: state.general.location
});

const mapDispatch = dispatch => ({
  updateWidth: () => dispatch(getWindowWidth()),
  toggleMenu: () => dispatch(toggleMobileMenu()),
  goHome: () => dispatch(resetEverything()),
  toggleContact: () => dispatch(toggleModalVisibility("showContact")),
  toggleServices: () => dispatch(toggleModalVisibility("showServices")),
  toggleTerms: () => dispatch(toggleModalVisibility("showTerms")),
  togglePrivacy: () => dispatch(toggleModalVisibility("showPrivacyPolicy"))
});

TemplateComponent.propTypes = {
  showMobileMenu: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(
  mapState,
  mapDispatch
)(TemplateComponent);
