import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Fade } from "reactstrap";
import { Frames } from 'frames-react';
import { withRouter } from "react-router-dom";

import {
  Button,
  StatusModal,
  FullInput,
  BraintreeDropIn,
  CheckoutSummary,
  CheckBox,
  IDSelector
} from "../components";
import CkoDropin from "../components/payment/dropins/CkoDropin";

import {
  setStep,
  requestClientToken,
  setPersonField,
  setPersonFieldDirty,
  onBlurPersonFieldValidation,
  paymentReadyStatusChange,
  submitOrder,
  setSubmitted,
  clickAcceptedTerms,
  toggleModalVisibility,
  uploadImage,
  checkIfCanSubmit,
  setFile,
} from "../store";
import { objectToArray, STEPS, phoneNumbers } from "../helpers";

class PaymentComponent extends Component {
  constructor(props) {
    super(props);
    if (process.env.REACT_APP_USE_BRAINTREE === 'true'){
      console.log('Braintree TRUE')
    this.state = {
      BraintreeInstance: null
    };
    }
    else {
      console.log(`Anti Fraud = ${process.env.REACT_APP_BT_ANTI_FRAUD}`)
      console.log(`Braintree = ${process.env.REACT_APP_USE_BRAINTREE}`)
    }
  }

  componentDidMount() {
    if (process.env.REACT_APP_USE_BRAINTREE === 'true') {
      if (!this.props.payment.clientToken) this.props.getClientToken();
    }
  }

  setInstance = instance =>  {
    if (process.env.REACT_APP_USE_BRAINTREE === 'true') this.setState({ BraintreeInstance: instance });
  }

  handleChange = (event) => {
    const target = event.target.name;
    const { value } = event.target;
    let safeValue = value;
    if (target === 'phoneNumber') {
      safeValue = phoneNumbers.cleanPhoneNumberInput(safeValue);
    }
    this.props.setField(target, safeValue);
  }

  handleBlur = (event) => {
    this.props.setFieldDirty(event.target.name);
    this.props.blurValidation(event.target.name);
  };

  handleImageSelection = (event, key) => {
    this.props.uploadImageId(event, key);
    this.props.checkSubmission();
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.props.payment.enableSubmit) {
      this.props.submitDisabled(true);
      if (process.env.REACT_APP_USE_BRAINTREE === 'true') {
        this.state.BraintreeInstance.requestPaymentMethod()
          .then(this.props.submit)
          .catch((err) => {
            console.log('error generating nonce', err) // eslint-disable-line
            return this.props.submitDisabled(false);
          });
      } else {
        try {
          await Frames.submitCard();
        } catch (error) {
          this.props.submitDisabled(false);
          console.error(error);
        }
      }
    }
  };

  render() {
    const {
      violations,
      viewViolationsPage,
      payment,
      person,
      search,
      general,
      toggleTerms,
      toggleViewPrivacy,
      toggleViewTerms,
    } = this.props;
    const selectedViolations = objectToArray(violations.selected);
    const numTickSel = selectedViolations.length;
    const { totalSelectedFines } = violations;
    let braintreeDropInOptions
    if (process.env.REACT_APP_USE_BRAINTREE === 'true') {
       braintreeDropInOptions = {
        authorization: payment.clientToken,
        card: {
          cardholderName: {
            required: true,
          },
        },

        // paypal: {
        //   flow: "checkout",
        //   amount: violations.orderTotal.toFixed(2),
        //   currency: "USD"
        // }
      };
      if (process.env.REACT_APP_BT_ANTI_FRAUD === 'true') {
        braintreeDropInOptions.dataCollector = {
          kount: true, // Required if Kount fraud data collection is enabled
          // paypal: true // Required if PayPal fraud data collection is enabled
        };
      }
      if (process.env.REACT_APP_THREED_ENABLED === 'true') {
        braintreeDropInOptions.threeDSecure = {
          amount: violations.orderTotal.toFixed(2),
        };
      }
    }
    return (
      <Fragment>
        {search.fetching && <StatusModal message="Uploading Image..." />}
        {payment.submitted ? (
          <StatusModal message="Submitting Your Order..." />
        ) : null}
        <Col>
          <Row>
            <Container>
              <Row>
                <Col lg={6} md={12}>
                  <CheckoutSummary
                    editViolations={viewViolationsPage}
                    text=   {(process.env.REACT_APP_USE_BRAINTREE === 'true') ? ("Your payment details are secured by Braintree.") : ("Your payment details are secured by Checkout.com.")}
                    violations={selectedViolations}
                    violationsTotal={violations.totalSelectedFines}
                    serviceFee={violations.serviceFeeAmount}
                    creditCardFeePercentage={violations.creditCardFeePercentage}
                    creditCardFee={violations.creditCardFeeAmount}
                    totalAdditionalTicketFee={violations.totalAdditionalTicketFee}
                    totalFees={violations.totalFees}
                    total={violations.orderTotal}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <Form onSubmit={this.handleSubmit}>
                    <Container>
                      <Row className="py-3">
                        <Col>
                          <h4>Enter Your Payment Info</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <FullInput
                            title="First Name"
                            name="firstName"
                            placeholder="First Name"
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                            value={person.firstName.value}
                            dirty={person.firstName.dirty}
                            error={person.firstName.error}
                            type="text"
                            className="uppercase"
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <FullInput
                            title="Last Name"
                            name="lastName"
                            placeholder="Last Name"
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                            value={person.lastName.value}
                            dirty={person.lastName.dirty}
                            error={person.lastName.error}
                            type="text"
                            className="uppercase"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FullInput
                            title="Phone Number"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                            value={
                              person.phoneNumber.value
                                ? phoneNumbers.usFormat(
                                  person.phoneNumber.value
                                ) : undefined
                            }
                            dirty={person.phoneNumber.dirty}
                            error={person.phoneNumber.error}
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FullInput
                            title="Email"
                            name="email"
                            placeholder="Email"
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                            value={person.email.value}
                            dirty={person.email.dirty}
                            error={person.email.error}
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FullInput
                            title="Email Confirmation"
                            name="emailConfirm"
                            placeholder="Email Confirmation"
                            handleChange={this.handleChange}
                            handleBlur={this.handleBlur}
                            value={person.emailConfirm.value}
                            dirty={person.emailConfirm.dirty}
                            error={person.emailConfirm.error}
                            disabled={person.emailConfirm.disabled}
                            type="text"
                          />
                        </Col>
                      </Row>
                      {(totalSelectedFines >= 500 || numTickSel > 2) ? (
                        <Row>
                          <Col>
                            <IDSelector
                              files={this.props.files}
                              setFile={this.props.setImageFile}
                              onImageSelect={this.handleImageSelection}
                            />
                          </Col>
                        </Row>
                      ) : null}
                      {(process.env.REACT_APP_USE_BRAINTREE === 'true') ? (
                        <Row>
                        <Col className="mx-auto">
                          <Fade in={!!payment.clientToken}>
                            {payment.clientToken ? (
                              <BraintreeDropIn
                                onInstance={this.setInstance}
                                onPaymentMethodRequestable={() => {
                                  this.props.paymentReady(true);
                                }}
                                onNoPaymentMethodRequestable={() => {
                                  this.props.paymentReady(false);
                                }}
                                shouldReset={!!general.error}
                                options={braintreeDropInOptions}
                              />
                            ) : (
                              "Preparing Secure Payment Form..."
                            )}
                          </Fade>
                        </Col>
                        </Row>
                        ) : <CkoDropin />}
                      <Row className="pt-3 font-weight-bold">
                        <Col>
                          <div className="d-flex flex-row">
                            <div className="d-flex justify-content-center align-items-center pr-1">
                              <CheckBox
                                checked={payment.acceptedTerms}
                                checkColor="#53c54a"
                                onClick={toggleTerms}
                              />
                            </div>
                            <div className="d-inline-flex flex-wrap justify-content-start align-items-center">
                              You accept our{" "}
                              <span
                                onClick={toggleViewTerms}
                                style={{
                                  margin: "0 0.5rem 0 0.5rem",
                                  textDecoration: "underline",
                                  cursor: "pointer"
                                }}
                              >
                                Terms of Service
                              </span>{" "}
                              and{" "}
                              <span
                                onClick={toggleViewPrivacy}
                                style={{
                                  margin: "0 0.5rem 0 0.5rem",
                                  textDecoration: "underline",
                                  cursor: "pointer"
                                }}
                              >
                                Privacy Policy.
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="p-3 justify-content-end">
                        {person.firstName.value ? (
                          <Button
                            color="green"
                            shadow
                            disabled={
                              !payment.enableSubmit || !payment.paymentReady || payment.submitted
                            }
                            type="submit"
                            onClick={this.handleSubmit}
                          >
                            Pay {numTickSel}
                            {numTickSel > 1 ? " Tickets" : " Ticket"}
                          </Button>
                        ) : (
                            ""
                          )}
                      </Row>
                    </Container>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Row>
        </Col>
      </Fragment>
    );
  }
}

const mapState = state => ({
  violations: state.violations,
  search: state.search,
  payment: state.payment,
  person: state.person,
  general: state.general,
  files: state.payment.files
});

const mapDispatch = dispatch => ({
  setImageFile: (key, file, imagePreviewUrl) => dispatch(setFile(key, file, imagePreviewUrl)),
  checkSubmission: () => dispatch(checkIfCanSubmit()),
  uploadImageId: (image, key) => dispatch(uploadImage(image, key)),
  toggleViewTerms: () => dispatch(toggleModalVisibility("showTerms")),
  toggleViewPrivacy: () => dispatch(toggleModalVisibility("showPrivacyPolicy")),
  toggleTerms: () => dispatch(clickAcceptedTerms()),
  viewViolationsPage: () => dispatch(setStep(STEPS.result)),
  getClientToken: () => dispatch(requestClientToken()),
  setField: (fieldName, fieldValue) => dispatch(setPersonField(fieldName, fieldValue)),
  setFieldDirty: fieldName => dispatch(setPersonFieldDirty(fieldName)),
  blurValidation: fieldName => dispatch(onBlurPersonFieldValidation(fieldName)),
  paymentReady: statusBool => dispatch(paymentReadyStatusChange(statusBool)),
  submitDisabled: bool => dispatch(setSubmitted(bool)),
  submit: (nonce,cardScheme) => dispatch(submitOrder(nonce,cardScheme))
});

export default connect(
  mapState,
  mapDispatch
)(withRouter(PaymentComponent));
