import io from 'socket.io-client';
import store, { setError, setFetchingStatus } from '../store';
import {
  receiveFees,
  receiveViolations,
  selectAllValidViolations,
} from '../store/violations';
import { setRecaptchaToken } from "../store/search"

import TRACKING from '../tracking/google';

let timer = {
  start: () => {},
  finish: () => {},
};
export const resetTimer = () => {
  timer = TRACKING.action.searchTimer();
  timer.start();
};

const socket = io(process.env.REACT_APP_BACKEND_URL);

socket.on('connect', () => {
  // console.log('Connected.');
});

socket.on('searchTickets', (result) => {
  // console.log("RESULT", result)
  timer.finish();
  store.dispatch(setRecaptchaToken(null));
  store.dispatch(receiveFees(result.fees));
  store.dispatch(receiveViolations(result.violations));
  return store.dispatch(selectAllValidViolations());
});
socket.on('searchTicketsError', (error) => {
  console.log("searchTicketsError ", error);
  store.dispatch(setFetchingStatus(false));
  timer.finish();
  store.dispatch(setRecaptchaToken(null));
  let message = "We could not perform the search at this time."
  if (error && error.message && typeof error.message === 'string') {
    message = error.message
  }
  return store.dispatch(setError({
    title: "Something went wrong.",
    message: message
  }));
});

export default socket;
