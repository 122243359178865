import React from "react";
import { Alert } from "reactstrap";

import "./errorAlert.css";

const ErrorAlert = props => {
  const {
    error: { title, message },
    errorToggle
  } = props;
  let displayText;
  if (Array.isArray(message)) {
    if (message.length > 1) {
      displayText = (
        <ul>
          {message.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      );
    } else {
      displayText = <p>{message[0]}</p>;
    }
  } else {
    displayText = <p>{message}</p>;
  }
  return (
    <div className="error-alert-container">
      <Alert color="danger" isOpen toggle={errorToggle}>
        <h4 className="alert-heading">
          {title || "Something Unexpected Happened"}
        </h4>
        {displayText ||
          "Sorry, we encountered an error while trying to fulfil your request."}
      </Alert>
    </div>
  );
};

export default ErrorAlert;
