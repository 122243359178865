import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  clearError,
  toggleModalVisibility,
  setOrderTimeoutWarningToggle
} from "../store";

import {
  ErrorAlert,
  PageModal,
  WhatWeOffer,
  ContactInfo,
  Terms,
  Privacy,
  OrderTimeoutWarning,
  CookiesDisabled
} from "../components";

const RenderModals = ({
  showServices,
  showContact,
  showTerms,
  showPrivacyPolicy,
  showCookiesDisabled,
  error,
  toggleError,
  toggleContact,
  toggleServices,
  toggleTerms,
  togglePrivacy,
  showTimeoutWarning,
  toggleTimeout,
  orderTimeoutTime
}) => (
    <Fragment>
      {error && <ErrorAlert errorToggle={toggleError} error={error} />}
      {showContact && (
        <PageModal size="lg" toggle={toggleContact} title="Contact Us">
          <ContactInfo />
        </PageModal>
      )}
      {showCookiesDisabled && (
        <PageModal hideToggle size="lg" toggle={() => { }} title="Cookies Required">
          <CookiesDisabled />
        </PageModal>
      )}
      {showServices && (
        <PageModal toggle={toggleServices} title="Our Services">
          <WhatWeOffer mini />
        </PageModal>
      )}
      {showTerms && (
        <PageModal toggle={toggleTerms} title="Terms of Service">
          <Terms />
        </PageModal>
      )}
      {showPrivacyPolicy && (
        <PageModal toggle={togglePrivacy} title="Privacy Policy">
          <Privacy />
        </PageModal>
      )}
      {showTimeoutWarning && (
        <PageModal size="sm" toggle={toggleTimeout} title="Warning!">
          <OrderTimeoutWarning time={orderTimeoutTime} />
        </PageModal>
      )}
    </Fragment>
  );

const mapState = state => ({
  error: state.general.error,
  showContact: state.general.showContact,
  showServices: state.general.showServices,
  showCookiesDisabled: state.general.showCookiesDisabled,
  showTerms: state.general.showTerms,
  showPrivacyPolicy: state.general.showPrivacyPolicy,
  showTimeoutWarning: state.general.orderTimeoutWarningToggle,
  orderTimeoutTime: state.general.orderTimeoutTime
});

const mapDispatch = dispatch => ({
  toggleError: () => dispatch(clearError()),
  toggleContact: () => dispatch(toggleModalVisibility("showContact")),
  toggleServices: () => dispatch(toggleModalVisibility("showServices")),
  toggleTerms: () => dispatch(toggleModalVisibility("showTerms")),
  togglePrivacy: () => dispatch(toggleModalVisibility("showPrivacyPolicy")),
  toggleTimeout: () => dispatch(setOrderTimeoutWarningToggle())
});

RenderModals.defaultProps = {
  error: false
};

RenderModals.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  toggleError: PropTypes.func.isRequired,
  toggleContact: PropTypes.func.isRequired,
  toggleServices: PropTypes.func.isRequired
};

export default connect(
  mapState,
  mapDispatch
)(RenderModals);
