const axiosError = error => {
  // if (process.env.NODE_ENV === "development") {
  console.log('error', error) //eslint-disable-line
  // }
  // if we send our own error object in response
  if (error.response && error.response.data && error.response.data.error) {
    if (process.env.NODE_ENV === "development") {
      console.log('Error Body -', error.response.data.error.body) //eslint-disable-line
    }
    return error.response.data.error;
  }
  // if the error is not our response
  return { message: error.message };
};

export default axiosError;
