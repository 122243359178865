import React from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import {
  setPaymentField,
} from "../../store";
import { COUNTRIES } from "../../helpers";

const BillingAddressCountry = ({ payment, setField }) => {
  const handleChange = (event) => {
    setField("billingAddressCountry", event.target.value);
    setField("billingAddressState", "")
  }

  const countrySelectOptions = COUNTRIES.map(country => {
    return {
      value: country.country,
      label: country.country,
    }
  })

  return (
    <Select
      value={
        payment.billingAddressCountry.value
          ? { value: payment.billingAddressCountry.value, label: payment.billingAddressCountry.value }
          : null
      }
      placeholder="Country"
      onChange={e => {
        const event = {
          target: {
            name: "plateState",
            value: e.value
          }
        };
        return handleChange(event);
      }}
      isSearchable
      options={countrySelectOptions}
    />
  );
};

const mapState = state => ({
  payment: state.payment,
});

const mapDispatch = dispatch => ({
  setField: (fieldName, fieldValue) => dispatch(setPaymentField(fieldName, fieldValue)),
});

export default connect(mapState, mapDispatch)(BillingAddressCountry);
