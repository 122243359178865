import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Row, Container } from "reactstrap";

import { setSearchType, setRecaptchaToken, setError } from "../store";

import RenderSearchForm from "./renderSearchForm";
import {
  StatusModal,
  SearchFormSelector,
  DescribeSite,
  FeaturedIn,
  WhatWeOffer,
  Recaptcha,
} from "../components";

import {
  CITY_TO_LANDING_LOGO_MAPPING,
} from '../helpers'

const Search = ({
  search,
  changeSearchType,
  setToken,
  onTokenError,
  recaptchaToken,
  location
}) => {
  const landingPageLogo = CITY_TO_LANDING_LOGO_MAPPING[location.name.toLowerCase()]
  return (
    <Col>
      {search.fetching && <StatusModal message="Searching for Tickets..." secondMessage="This may take a minute." />}
      <Row>
        <Container>
          <Col sm={12} md lg>
            <DescribeSite location={location}/>
          </Col>
        </Container>
      </Row>
      <Row>
        <Container>
          <Col>
            <Container>
              <Row className="pb-3">
                <SearchFormSelector
                  currentSearchType={search.searchType}
                  changeSearchType={changeSearchType}
                />
              </Row>
              <Row>
                <RenderSearchForm />
              </Row>
              <Row className="pt-3 justify-content-end">
                <Recaptcha token={recaptchaToken} onChange={setToken} onErrored={onTokenError} />
              </Row>
            </Container>
          </Col>
        </Container>
      </Row>
      <Row className="pt-5" style={{ overflow: "hidden" }}>
        <Container>
          <Col
            sm={12}
            md
            lg
            className="d-flex justify-content-center align-items-center"
          >
            { landingPageLogo ? <img src={landingPageLogo.img} alt={landingPageLogo.alt} /> : ''}
          </Col>
        </Container>
      </Row>
      <Row>
        <Container className="py-5">
          <Col>
            <WhatWeOffer />
          </Col>
        </Container>
      </Row>
      <Row className="border-bottom border-gray-line" />
      <Row>
        <Container className="py-5">
          <Col sm={12} md lg>
            <FeaturedIn />
          </Col>
        </Container>
      </Row>
    </Col>
  )
};

Search.propTypes = {
  search: PropTypes.shape({
    searchType: PropTypes.string,
    fetching: PropTypes.bool,
  }).isRequired,
  changeSearchType: PropTypes.func.isRequired,
};

const mapState = state => ({
  search: state.search,
  recaptchaToken: state.search.recaptchaToken,
  location: state.general.location,
});

const mapDispatch = dispatch => ({
  changeSearchType: searchType => dispatch(setSearchType(searchType)),
  setToken: token => dispatch(setRecaptchaToken(token)),
  onTokenError: (error) => {
    console.log("captcha error", error);
    dispatch(setError({
      title: "Captcha Error",
      message: "Captcha enountered a network error. Please try again later."
    }));
  }
});

export default connect(
  mapState,
  mapDispatch,
)(Search);
