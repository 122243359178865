import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import classnames from "classnames";

import "./searchForms.css";

import { PLATE_STATES } from "../../helpers";

import customStyles from "../../style/reactSelectStyleSearch";

const FullSearchForm = ({
  handleSubmit,
  lastName,
  plateNumber,
  plateState,
  handleChange,
  disabled,
  location,
}) => {
  const stateSelect = Object.keys(PLATE_STATES).map(state => ({
    value: state,
    label: PLATE_STATES[state],
  }));
  return (
    <form onSubmit={handleSubmit} className="d-inline-flex flex-grow-1">
      <div className="search-form-container">
        {/*We do not want to show the lastName input when not on Chicago app*/}
        {location.name.toLowerCase() === 'chicago' && <div className="search-form-divide">
          <input
            className="search-form-input uppercase"
            value={lastName}
            onChange={handleChange}
            component="input"
            disabled={disabled}
            type="text"
            name="lastName"
            id="lastName"
            size="35"
            placeholder="Owner Last Name/Business Name"
          />
        </div>}
        <div className="search-form-divide">
          <input
            className={classnames({
              "search-form-input": true,
              "search-form-input-capitalize": !!plateNumber,
            })}
            value={plateNumber}
            onChange={handleChange}
            component="input"
            disabled={disabled}
            type="text"
            name="plateNumber"
            id="plateNumber"
            placeholder="Plate Number"
          />
        </div>
        <div className="search-form-divide">
          <Select
            styles={customStyles}
            value={
              plateState
                ? { value: plateState, label: PLATE_STATES[plateState] }
                : undefined
            }
            placeholder="State"
            onChange={e => {
              const event = {
                target: {
                  name: "plateState",
                  value: e.value
                }
              };
              return handleChange(event);
            }}
            isDisabled={disabled}
            isSearchable
            options={stateSelect}
          />
        </div>
        <button
          className="search-form-submit"
          disabled={disabled}
          onClick={handleSubmit}
        >
          Search Tickets
        </button>
      </div>
    </form>
  );
};

FullSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  plateNumber: PropTypes.string.isRequired,
  plateState: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapState = state => ({
  location: state.general.location,
});

export default connect(mapState)(FullSearchForm);
