import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension' //eslint-disable-line
import axios from "axios";

import general from "./general";
import person from "./person";
import payment from "./payment";
import search from "./search";
import violations from "./violations";

import { BACKEND_URL } from "../helpers";

const reducers = combineReducers({
  payment,
  person,
  general,
  search,
  violations
});

let middlewares;

if (process.env.NODE_ENV !== "production") {
  middlewares = composeWithDevTools(applyMiddleware(thunkMiddleware));
} else {
  middlewares = applyMiddleware(thunkMiddleware);
}

export const savePersistedApplicationState = async (state, paymentId) => {
  let result

  try {
    result = await axios.post(
      `${BACKEND_URL}/persistedState`, 
      { state: JSON.stringify(state), paymentId }
    )
  } catch (error) {
    console.error(error)
    return false
  }

  if (result.status === 200) {
    return true
  } else {
    return false
  }
}

export const getPersistedApplicationState = async (sessionId) => {
  let result

  try {
    result = await axios.get(`${BACKEND_URL}/persistedState/${sessionId}`)
  } catch (error) {
    console.error(error)
    return false
  }

  if (result.status === 200) {
    return JSON.parse(result.data.state)
  } else {
    return false
  }
}

const store = createStore(reducers, middlewares);

export default store;
export * from "./general";
export * from "./payment";
export * from "./person";
export * from "./search";
export * from "./violations";
