import React from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import {
  setPaymentField,
} from "../../store";
import { COUNTRIES } from "../../helpers";

const BillingAddressState = ({ payment, setField }) => {
  const handleChange = (event) => {
    setField("billingAddressState", event.target.value);
  }

  const selectedCountry = COUNTRIES.find((countryObj) => countryObj.country === payment.billingAddressCountry.value)

  const stateSelectOptions = selectedCountry.states.map((state) => {
    return {
      value: state,
      label: state,
    }
  })

  return (

    <Select
      value={
        payment.billingAddressState.value ? 
        { value: payment.billingAddressState.value, label: payment.billingAddressState.value } : 
        null
      }
      placeholder="State"
      onChange={e => {
        const event = {
          target: {
            name: "plateState",
            value: e.value
          }
        };
        return handleChange(event);
      }}
      isSearchable
      options={stateSelectOptions}
    />
  );
};

const mapState = state => ({
  payment: state.payment,
});

const mapDispatch = dispatch => ({
  setField: (fieldName, fieldValue) => dispatch(setPaymentField(fieldName, fieldValue)),
});

export default connect(mapState, mapDispatch)(BillingAddressState);
