import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";

import {
  ViolationsSelectorTable,
  ViolationsSelectorTableHeader,
  ViolationsToolBarTop,
  ViolationsToolBarBottom
} from "../components";

import {
  addOrRemoveViolation,
  setStep,
  resetSelectedViolations,
  resetEverything,
  toggleSelectAll,
  handleViolationsFilterChange
} from "../store";

import { STEPS, objectToArray } from "../helpers";

const Violations = ({
  violations,
  toggleViolation,
  goToPayment,
  clearSelected,
  restartSearch,
  search,
  selectAll,
  handleFilterChange,
  clearFilter
}) => {
  const violationsArray = violations.filterText
    ? violations.filterResults
    : violations.items;
  const selectedViolationsArray = objectToArray(violations.selected);
  return (
    <Container>
      <Row>
        <Col>
          <ViolationsToolBarTop
            violations={violations}
            restartSearch={restartSearch}
            clearSelected={clearSelected}
            search={search}
            searchType={search.searchType}
            selectedViolations={selectedViolationsArray}
            selectAll={selectAll}
            handleFilterChange={handleFilterChange}
          />
          <ViolationsSelectorTableHeader />
          <ViolationsSelectorTable
            selected={violations.selected}
            violations={violationsArray}
            toggleViolation={toggleViolation}
            clearFilter={clearFilter}
            filterText={violations.filterText}
          />
          <ViolationsToolBarBottom
            selectedViolations={selectedViolationsArray}
            totalFines={violations.totalSelectedFines}
            goToPayment={goToPayment}
            searchType={search.searchType}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapState = state => ({
  violations: state.violations,
  search: state.search
});

const mapDispatch = dispatch => ({
  toggleViolation: id => dispatch(addOrRemoveViolation(id)),
  clearSelected: () => dispatch(resetSelectedViolations()),
  restartSearch: () => dispatch(resetEverything()),
  goToPayment: () => dispatch(setStep(STEPS.payment)),
  selectAll: () => dispatch(toggleSelectAll()),
  handleFilterChange: event =>
    dispatch(handleViolationsFilterChange(event.target.value)),
  clearFilter: () => dispatch(handleViolationsFilterChange(""))
});

Violations.propTypes = {
  violations: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  toggleViolation: PropTypes.func.isRequired,
  clearSelected: PropTypes.func.isRequired,
  restartSearch: PropTypes.func.isRequired,
  goToPayment: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired
};

export default connect(
  mapState,
  mapDispatch
)(Violations);
