import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./button.css";

const ButtonComponent = ({
  type,
  onClick,
  disabled,
  children,
  color,
  shadow
}) => (
  <button
    type={type}
    disabled={disabled}
    onClick={onClick}
    className={classnames({
      "button-component": true,
      "button-component-blue": color === "blue",
      "button-component-blue-shadow": color === "blue" && shadow,
      "button-component-green": color === "green",
      "button-component-green-shadow": color === "green" && shadow
    })}
  >
    {children}
  </button>
);

ButtonComponent.defaultProps = {
  shadow: false,
  type: "submit",
  color: "blue",
  children: "Submit",
  disabled: false,
  onClick: e => e.preventDefault()
};

ButtonComponent.propTypes = {
  type: PropTypes.string,
  color: PropTypes.oneOf(["blue", "green", ""]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  shadow: PropTypes.bool,
  onClick: PropTypes.func
};

export default ButtonComponent;
