import React from 'react';
import { connect } from "react-redux";
import {
  setPaymentField,
  setPaymentFieldDirty,
  onBlurPaymentFieldValidation,
} from "../../store";
import FullInput from '../forms/fullInput';

const BillingAddressCity = ({ payment, setField, setFieldDirty, blurValidation }) => {
  const handleChange = (event) => {
    setField("billingAddressCity", event.target.value);
  }

  const handleBlur = (event) => {
    setFieldDirty(event.target.name);
    blurValidation(event.target.name);
  }

  return (
    <FullInput
      title="City"
      placeholder="City"
      name="billingAddressCity"
      handleChange={handleChange}
      handleBlur={handleBlur}
      value={payment.billingAddressCity.value}
      dirty={payment.billingAddressCity.dirty}
      error={payment.billingAddressCity.error}
      type="text"
      className="uppercase"
    />
  );
};

const mapState = state => ({
  payment: state.payment,
});

const mapDispatch = dispatch => ({
  setField: (fieldName, fieldValue) => dispatch(setPaymentField(fieldName, fieldValue)),
  setFieldDirty: fieldName => dispatch(setPaymentFieldDirty(fieldName)),
  blurValidation: fieldName => dispatch(onBlurPaymentFieldValidation(fieldName)),
});

export default connect(mapState, mapDispatch)(BillingAddressCity);
