import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import history from "./history";
import GoogleAnalytics from "./tracking/google";
import GoogleTagManager from "./tracking/googleTagManager";

import Template from "./template";
import store from "./store";

import Rendersteps from "./containers/renderSteps";
import Unsubscribe from "./containers/unsubscribe";

// for google analytics
GoogleAnalytics.initialize();

// for google Tag Manager
GoogleTagManager.initialize();

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <Template>
        <Switch>
          <Route path="/unsubscribe" component={Unsubscribe} />
          <Route path="*" component={Rendersteps} />
        </Switch>
      </Template>
    </Router>
  </Provider>
);

export default App;
