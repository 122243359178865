import React from "react";
import ReactDOM from "react-dom";

import "./style/index.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";
// import registerServiceWorker from "./registerServiceWorker";
unregister();


ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();

