import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import "./IDSelector.css";

class IDSelector extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     id: {
  //       file: "",
  //       imagePreviewUrl: "",
  //     },
  //     creditCard: {
  //       file: "",
  //       imagePreviewUrl: "",
  //     }

  //   };
  // }

  getImagePreview = (key) => {
    const imageObject = this.props.files[key];
    let imagePreview = null;
    if (imageObject.imagePreviewUrl) {
      imagePreview = (
        <div
          className="actual-img"
          style={{
            backgroundImage: `url(${this.props.files[key].imagePreviewUrl})`,
          }}
        />
      );
    } else {
      imagePreview = (
        <div className="inputfile-previewText">No Image</div>
      );
    }
    return imagePreview;
  };

  handleImageChange = (e, key) => {
    e.preventDefault();
    if (
      !e.target ||
      !e.target.files ||
      !e.target.files[0] ||
      !e.target.files[0].type
    ) {
      return false;
    }

    const file = e.target.files[0];

    if (!["image/jpeg", "image/png", "image/gif"].includes(file.type)) {
      alert("We only support .jpg, .jpeg, .png, and .gif files.");
      return false;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      this.props.setFile(key, file, reader.result);
    };
    if (this.props.onImageSelect) {
      this.props.onImageSelect(file, key);
    }
    try {
      reader.readAsDataURL(file);
    } catch (err) {
      console.log('error setting readAsDataURL', err)
    }
  };

  render() {
    return (
      <Fragment>
        <Row className="pb-2">
          <Col>
            <div className="required-notice">
              <div className="pb-2">
                For your security, we need to verify your identity before completing this transaction.
                Please upload an image of a valid state issued identification.
              </div>
              <div className="select-img-group">
                <Row>
                  <Col>
                    <div className="select-img-title">Identification</div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex align-items-center">
                    {this.props.files.id.imagePreviewUrl ? (
                      <div className="inputfile-imgPreview">
                        {this.getImagePreview("id")}
                      </div>
                    ) : null}

                    <input
                      type="file"
                      name="fileId"
                      id="fileId"
                      className="inputfile"
                      onChange={e => this.handleImageChange(e, "id")}
                    />
                    <label htmlFor="fileId">{this.props.files.id.imagePreviewUrl ? "Select Different Image" : "Select Image"}</label>
                  </Col>
                </Row>
              </div>
              {/* <div className="select-img-group">
                <Row>
                  <Col>
                    <div className="select-img-title">Credit or Debit Card</div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex align-items-center">
                    {this.props.files.creditCard.imagePreviewUrl ? (
                      <div className="inputfile-imgPreview">
                        {this.getImagePreview("creditCard")}
                      </div>
                    ) : null}

                    <input
                      type="file"
                      name="fileCC"
                      id="fileCC"
                      className="inputfile"
                      onChange={e => this.handleImageChange(e, "creditCard")}
                    />
                    <label htmlFor="fileCC">
                      {this.props.files.creditCard.imagePreviewUrl ? "Select Different Image" : "Select Image"}
                    </label>

                  </Col>
                </Row>
              </div> */}
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default IDSelector;
