import axios from "axios";
import { Frames } from 'frames-react';

import {
  setError,
  clearError,
  setFetchingStatus,
  setStep,
  savePersistedApplicationState,
} from "./index";

import {
  axiosError,
  BACKEND_URL,
  SEARCH_TYPES,
  visitorInfo,
  validate,
  STEPS,
  checkCookiesDisabled
} from "../helpers";

import GoogleAnalytics from "../tracking/google";
/**
 * DEFAULT STATE
 */

const defaultState = {
  files: {
    id: {
      file: "",
      imagePreviewUrl: "",
      id: ""
    },
    creditCard: {
      file: "",
      imagePreviewUrl: "",
      id: ""
    }
  },
  enableSubmit: false, // allow form to submit
  paymentReady: false, // CKO dropin callback will flip this when it has fully loaded and is ready for user input
  isCardValid: false, // CKO dropin callback sets this accordingly as card/payment info is changed/updated
  clientToken: null, // needed for Braintree drop-in form
  result: null, // to show info for confirmation
  submitted: false, // has pay button been clicked
  acceptedTerms: false,
  hostingPageLink: null,
  billingAddressLine1: { value: "", error: "", dirty: process.env.REACT_APP_USE_BRAINTREE === 'false' },
  billingAddressLine2: { value: "", error: "", dirty: process.env.REACT_APP_USE_BRAINTREE === 'false' },
  billingAddressZip: { value: "", error: "", dirty: process.env.REACT_APP_USE_BRAINTREE === 'false' },
  billingAddressCity: { value: "", error: "", dirty: process.env.REACT_APP_USE_BRAINTREE === 'false' },
  // State & Country can likely be refactored to primitive fields -> Input data is controlled via dropdowns
  billingAddressState: { value: "", error: "", dirty: process.env.REACT_APP_USE_BRAINTREE === 'false' },
  billingAddressCountry: { value: "United States", error: "", dirty: process.env.REACT_APP_USE_BRAINTREE === 'false' },
};

/**
 * ACTION TYPES
 */

const LOAD_PAYMENT_STATE = "LOAD_PAYMENT_STATE";
const SET_CLIENT_TOKEN = "SET_CLIENT_TOKEN";
const RESET_PAYMENT = "RESET_PAYMENT";
const SET_DROP_IN = "SET_DROP_IN";
const SET_PAYMENT_READY = "SET_PAYMENT_READY";
const SET_CARD_VALID = "SET_CARD_VALID";
const SET_ENABLE_SUBMIT = "SET_ENABLE_SUBMIT";
const SET_PAYMENT_RESULT = "SET_PAYMENT_RESULT";
const SET_SUBMITTED = "SET_SUBMITTED";
const TOGGLE_ACCEPTED_TERMS = "TOGGLE_ACCEPTED_TERMS";
const SET_HOSTING_PAGE_LINK= "SET_HOSTING_PAGE_LINK";
const SET_UPLOADED_IMAGE_ID = "SET_UPLOADED_IMAGE_ID";
const SET_FILE = "SET_FILE";
const SET_PAYMENT_FIELD_VALUE = "SET_PAYMENT_FIELD_VALUE";
const SET_PAYMENT_FIELD_DIRTY = "SET_PAYMENT_FIELD_DIRTY";
const SET_PAYMENT_FIELD_ERROR = "SET_PAYMENT_FIELD_ERROR";

/**
 * ACTION CREATORS
 */
export const loadPaymentState = state => ({ type: LOAD_PAYMENT_STATE, state });
export const setClientToken = token => ({ type: SET_CLIENT_TOKEN, token });
export const resetPayment = () => ({ type: RESET_PAYMENT });
export const setDropIn = showBool => ({ type: SET_DROP_IN, showBool });
export const setPaymentReady = readyBool => ({
  type: SET_PAYMENT_READY,
  readyBool
});
export const setIsCardValid = validBool => ({
  type: SET_CARD_VALID,
  validBool
});
export const setEnableSubmit = readyBool => ({
  type: SET_ENABLE_SUBMIT,
  readyBool
});
export const setPaymentResult = result => ({
  type: SET_PAYMENT_RESULT,
  result
});
export const setSubmitted = readyBool => ({ type: SET_SUBMITTED, readyBool });
export const toggleAcceptedTerms = () => ({ type: TOGGLE_ACCEPTED_TERMS });
export const setHostingPageLink = () => ({ type: SET_HOSTING_PAGE_LINK });
export const setUploadedImageId = (id, key) => ({ type: SET_UPLOADED_IMAGE_ID, id, key });
export const setFile = (key, file, imagePreviewUrl) => ({ type: SET_FILE, key, file, imagePreviewUrl })
export const setPaymentFieldValue = (fieldName, fieldValue) => ({
  type: SET_PAYMENT_FIELD_VALUE,
  fieldName,
  fieldValue
});
export const setPaymentFieldDirty = fieldName => ({
  type: SET_PAYMENT_FIELD_DIRTY,
  fieldName
});
export const setPaymentFieldError = (fieldName, fieldError) => ({
  type: SET_PAYMENT_FIELD_ERROR,
  fieldName,
  fieldError
});

/**
 * THUNK CREATORS
 */

export const loadPaymentPersistedState = (state) => dispatch => {
  return dispatch(loadPaymentState(state));
};

export const checkIfCanSubmit = () => (dispatch, getState) => {
  const { payment, person, violations } = getState();
  if (
    payment.acceptedTerms &&
    payment.paymentReady &&
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : payment.isCardValid) &&
    !person.firstName.error &&
    person.firstName.dirty &&
    !person.lastName.error &&
    person.lastName.dirty &&
    !person.phoneNumber.error &&
    person.phoneNumber.dirty &&
    !person.email.error &&
    person.email.dirty &&
    !person.emailConfirm.error &&
    person.emailConfirm.dirty &&
    !person.emailConfirm.disabled &&
    (person.email.value.toLowerCase() === person.emailConfirm.value.toLowerCase()) &&
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : payment.billingAddressLine1.dirty) &&
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : !payment.billingAddressLine1.error) &&
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : !payment.billingAddressLine2.error) && // Address line 2 is optional so just check for error
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : payment.billingAddressCity.dirty) &&
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : !payment.billingAddressCity.error) &&
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : payment.billingAddressState.value) &&
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : payment.billingAddressZip.dirty) &&
    (process.env.REACT_APP_USE_BRAINTREE === 'true' ? true : !payment.billingAddressZip.error)
  ) {
    // Check if we need additional info
    const ticketCount = Object.keys(violations.selected);
    if (
      (violations.totalSelectedFines >= 500 || ticketCount.length > 2) &&
      !payment.files.id.id
    ) {
      return dispatch(setEnableSubmit(false));
    }
    return dispatch(setEnableSubmit(true));
  }
  return dispatch(setEnableSubmit(false));
};

export const clickAcceptedTerms = () => dispatch => {
  dispatch(toggleAcceptedTerms());
  return dispatch(checkIfCanSubmit());
};

export const paymentReadyStatusChange = status => dispatch => {
  dispatch(setPaymentReady(status));
  return dispatch(checkIfCanSubmit());
};

export const cardValidityStatusChange = bool => dispatch => {
  dispatch(setIsCardValid(bool));
  return dispatch(checkIfCanSubmit());
};

export const uploadImage = (image, key) => dispatch => {
  dispatch(clearError());
  dispatch(setFetchingStatus(true));
  const extension = image.type.split("/")[1];
  return axios
    .post(`${BACKEND_URL}/checkout/imageUpload/${extension}`, image, {
      withCredentials: true,
      headers: {
        'Content-Type': image.type
      }
    })
    .then(res => {
      dispatch(setFetchingStatus(false));
      // console.log('res.data', res.data)
      dispatch(setUploadedImageId(res.data.imageId, key));
    })
    .catch(error => {
      dispatch(setFetchingStatus(false));
      console.log('error uploading user image', error) //eslint-disable-line
      const message = axiosError(error);
      return dispatch(setError(message));
    });
};

export const requestClientToken = () => (dispatch) => {
  checkCookiesDisabled()
    .then((cookiesDisabled) => {
      if (cookiesDisabled) {
        dispatch(setFetchingStatus(false));
        return dispatch(setError({
          title: "Cookies are required!",
          message: "Please enable cookies in your web browser."
        }));
      }
      return axios
        .get(`${BACKEND_URL}/checkout/clientToken`, { withCredentials: true })
        .then((res) => {
          dispatch(setClientToken(res.data));
        })
        .catch((error) => {
          console.log('error in request client token', error) //eslint-disable-line
          const message = axiosError(error);
          return dispatch(setError(message));
        });
    });
};

export const submitOrder = (token,cardScheme) => (dispatch, getState) => {
  dispatch(clearError());
  const state = getState();
  let params = {};
  switch (state.search.searchType) {
    case SEARCH_TYPES.full: {
      params = {
        plateNumber: state.search.fullSearch.plateNumber,
        plateState: state.search.fullSearch.plateState,
        plateOwnerName: state.search.fullSearch.lastName,
      };
      break;
    }
    case SEARCH_TYPES.ticket: {
      params = state.search.ticketSearch;
      break;
    }
    default:
      break;
  }
  // state.violations.select is an object with violation numbers as keys
  const violationNumbers = Object.keys(state.violations.selected)
  const selectedTicketsIds = violationNumbers.map((key) => state.violations.selected[key]._id)
  const licensePlate = violationNumbers.map((key) => state.violations.selected[key].licensePlate)[0]
  if (process.env.REACT_APP_USE_BRAINTREE === 'true')
    return axios
      .post(`${BACKEND_URL}/checkout/submit`, {
        params,
        visitorInfo: visitorInfo(),
        uploadedImageID: (state.payment.files.id.id) ? [
          state.payment.files.id.id,
          // state.payment.files.creditCard.id,
        ] : null,
        person: {
          firstName: state.person.firstName.value,
          lastName: state.person.lastName.value,
          phoneNumber: state.person.phoneNumber.value,
          email: state.person.email.value,
        },
        selectedTicketsIds,
        plateNumber: licensePlate,
        clientNonce: token,
        city: state.general.location,
      }, { withCredentials: true })
      .then(res => {
        dispatch(setPaymentResult(res.data));
        GoogleAnalytics.action.submitPayment(state.violations.orderTotal);
        return dispatch(setStep(STEPS.done));
      })
      .catch(error => {
        dispatch(setPaymentReady(false));
        dispatch(setSubmitted(false));
        console.log('error in submit order', error) //eslint-disable-line
        const message = axiosError(error);
        try {
          GoogleAnalytics.action.submitPaymentError(JSON.stringify(message));
        } catch (err) {
          console.log(err);
        }
        return dispatch(setError(message));
      });
  else
  return axios
    .post(`${BACKEND_URL}/initiatePayment`, { // Calling new backend route which will get a link for 3ds
      params,
      visitorInfo: visitorInfo(),
      uploadedImageID: (state.payment.files.id.id) ? [
        state.payment.files.id.id,
        // state.payment.files.creditCard.id,
      ] : null,
      person: {
        firstName: state.person.firstName.value,
        lastName: state.person.lastName.value,
        phoneNumber: state.person.phoneNumber.value,
        email: state.person.email.value,
        billingAddress: {
          addressLine1: state.payment.billingAddressLine1.value,
          addressLine2: state.payment.billingAddressLine2.value,
          zip: state.payment.billingAddressZip.value,
          city: state.payment.billingAddressCity.value,
          state: state.payment.billingAddressState.value,
          country: state.payment.billingAddressCountry.value,
        },
      },
      selectedTicketsIds,
      plateNumber: licensePlate,
      clientToken: token,
      cardScheme: cardScheme,
      city: state.general.location,
    }, { withCredentials: true })
    .then(res => {
      // CKO api may or may not require a "challenge flow" for 3ds. If so, we need to be able to
      // leave our domain and come back after the user completes the 3ds challenge with persisted app state.
      if (res.data.error)
        return dispatch(setError(res.data.error))
      const { redirectLink, ckoPaymentId } = res.data
      if (!redirectLink) {
        savePersistedApplicationState(getState(), ckoPaymentId).then(() => {
          dispatch(savePaymentDetails(ckoPaymentId))
          dispatch(setStep(STEPS.done))
        })
      } else {
        return savePersistedApplicationState(getState(), ckoPaymentId).then(() => {
          window.location.replace(redirectLink)
        })
      }
      // Not sure if we still get confirmation code like with bt? Payment page breaks without this value (see src\containers\complete.js)
      // This code will need a new home as it is now unreachable (with CKO payments)
      // dispatch(setPaymentResult(res.data));
      // GoogleAnalytics.action.submitPayment(state.violations.orderTotal);
    })
    .catch(error => {
      Frames.init(); // Allow the CKO dropin to recover from the error and re-render
      dispatch(setPaymentReady(false));
      dispatch(setSubmitted(false));
      console.log('error in submit order', error) //eslint-disable-line
      const message = axiosError(error);
      try {
        GoogleAnalytics.action.submitPaymentError(JSON.stringify(message));
      } catch (err) {
        console.log(err);
      }
      return dispatch(setError(message));
    });
};

export const savePaymentDetails = (ckoSessionId) => async (dispatch, getState) => {
  const state = getState();
  const violationNumbers = Object.keys(state.violations.selected)
  const data = {
    ckoSessionId,
    uploadedImageID: (state.payment.files.id.id) ? [
      state.payment.files.id.id,
    ] : null,
    selectedTicketsIds: violationNumbers.map((key) => state.violations.selected[key]._id),
    city: state.general.location
  }

  // TODO handle errors
  try {
    await axios.post(`${BACKEND_URL}/savePaymentDetails`, data);
    return true
  } catch (error) {
    console.error('Error while saving payment details: ', error);
    return false
  }
}

export const getFailureMessage = (ckoSessionId) => dispatch => {
  dispatch(clearError());

  axios
    .post(`${BACKEND_URL}/checkoutFailure`, { ckoSessionId }, { withCredentials: true })
    .then((res) => {
       return dispatch(setError(res.data.error));
    })
    .catch((err) => {
      console.log(err);
    });
}

export const onBlurPaymentFieldValidation = () => dispatch => dispatch(checkIfCanSubmit());

export const onChangePaymentFieldValidation = (fieldName, fieldValue) => (
  dispatch,
  getState
) => {
  const field = getState().payment[fieldName];
  switch (fieldName) {
    case "billingAddressLine1":
      if (field.dirty) {
        if (!fieldValue.length) {
          dispatch(
            setPaymentFieldError(
              fieldName,
              "Billing address is a required field."
            )
          );
        } else {
          dispatch(setPaymentFieldError(fieldName, ""));
        }
      }
      break;
    case "billingAddressCity":
      if (field.dirty) {
        if (!fieldValue.length) {
          dispatch(
            setPaymentFieldError(
              fieldName,
              "City is a required field."
            )
          );
        } else {
          dispatch(setPaymentFieldError(fieldName, ""));
        }
      }
      break;
    case "billingAddressZip":
      if (field.dirty) {
        const isValidZipCode = validate.zipCode(fieldValue);
        if (!fieldValue.length) {
          dispatch(setPaymentFieldError(fieldName, "Zip code is a required field."));
        } else if (!isValidZipCode) {
          dispatch(setPaymentFieldError(fieldName, "Invalid zip code."));
        } else {
          dispatch(setPaymentFieldError(fieldName, ""));
        }
      }
      break;
    default:
      break;
  }
  return dispatch(checkIfCanSubmit());
};

export const setPaymentField = (fieldName, fieldValue) => dispatch => {
  dispatch(setPaymentFieldValue(fieldName, fieldValue));
  dispatch(onChangePaymentFieldValidation(fieldName, fieldValue));
  return dispatch(checkIfCanSubmit());
};

export const getCkoPaymentIdFromSessionId = (sessionId) => {
  return axios
    .get(`${BACKEND_URL}/initiatePayment/ckoPaymentIdFromSessionId/${sessionId}`)
    .then(response => response.data.ckoPaymentId)
};

/**
 * REDUCER
 */

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOAD_PAYMENT_STATE:
      return action.state;
    case SET_FILE: {
      const files = { ...state.files };
      files[action.key] = {
        file: action.file,
        imagePreviewUrl: action.imagePreviewUrl
      };
      return { ...state, files };
    }
    case SET_UPLOADED_IMAGE_ID: {
      const files = { ...state.files };
      files[action.key] = {
        ...files[action.key],
        id: action.id
      };
      return { ...state, files };
    }
    case SET_CLIENT_TOKEN:
      return { ...state, clientToken: action.token };
    case RESET_PAYMENT:
      return { ...state, person: defaultState.person };
    case SET_DROP_IN:
      return { ...state, showDropIn: action.showBool };
    case SET_PAYMENT_READY:
      return { ...state, paymentReady: action.readyBool };
    case SET_CARD_VALID:
      return { ...state, isCardValid: action.validBool };
    case SET_ENABLE_SUBMIT:
      return { ...state, enableSubmit: action.readyBool };
    case SET_SUBMITTED:
      return { ...state, submitted: action.readyBool };
    case SET_PAYMENT_RESULT:
      return { ...state, result: action.result };
    case SET_HOSTING_PAGE_LINK:
      return { ...state, hostingPageLink: action.result };
    case TOGGLE_ACCEPTED_TERMS:
      return { ...state, acceptedTerms: !state.acceptedTerms };
    case SET_PAYMENT_FIELD_VALUE: {
      const newState = { ...state };
      newState[action.fieldName].value = action.fieldValue;
      return newState;
    }
    case SET_PAYMENT_FIELD_DIRTY: {
      const newState = { ...state };
      newState[action.fieldName].dirty = true;
      return newState;
    }
    case SET_PAYMENT_FIELD_ERROR: {
      const newState = { ...state };
      newState[action.fieldName].error = action.fieldError;
      return newState;
    }
    default:
      return state;
  }
}
