import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import './GrowthlinkClickFeed.scss';
import Spinner from './spinner';

const GrowthlinkClickFeed = () => {
  useEffect(() => {
    // This script requires jquery (which is loaded from a CDN in the app's index.html)
    // and a query string "?pc=" to be present in the URL in order to load correctly
    // (see step route map in /src/containers/renderSteps.js)
    const script = document.createElement('script');
    script.src = 'https://d3iv2l0es6sf8g.cloudfront.net/1023/gl/auto/cfad_s.js';
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      window.cf_ads('cf_result');
    });

    const spinner = document.querySelector('.spinner')

    // The script above will insert an <img> into the DOM. We want to know when it has finished loading.
    document.addEventListener('DOMNodeInserted', (e) => {
      // Attempt to get a reference to the image
      const image = document.querySelector('#cf_result').querySelector('img')
      // Several events are fired for DOMNodeInserted (not always the one we need (scripts, styles, etc)).
      // We only want to run this code if the ad image has loaded in the DOM:
      if (image) {
        image.addEventListener('load', () => {
          // Remove the spinner from the DOM
          spinner.remove()
        })
      }
    })

    document.querySelector('.clickfeed div button').addEventListener('click', () => {
      document.querySelector('#cf_result a').click();
    }, false);

    return () => {
      document.body.removeChild(script);
    }
  }, [])

  return (
    <Container className="clickfeed-container">
      <div className="clickfeed">
        <p>One last reminder before you go. Maintain valid car insurance to avoid additional violations. Get a free quote from one of our trusted partners below.</p>
        <h3>Please click &quot;Continue&quot; to Proceed:</h3>
        <div><button type="submit">CONTINUE</button></div>
        <div className="cf_flex">
          <div className="sponsor">Sponsored by</div>
          <Spinner />
          <div id="cf_result" />
        </div>
      </div>
    </Container>
  );
};

export default GrowthlinkClickFeed;
