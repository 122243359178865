import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";

import GrowthlinkClickFeed from "../components/GrowthlinkClickFeed";
import { objectToArray, ADWORDS_LABELS } from "../helpers";
import { CheckoutSummary } from "../components";
import GoogleConversionTracking from "../tracking/googleAdConversion";
import microsoftConversions from '../tracking/microsoftAdConversion'

const Complete = props => {
  const { 
    person, 
    violations, 
    general, 
    isMobile,
    result, 
  } = props;

  const selectedViolations = objectToArray(violations.selected);
  const city = general.location.name.toLowerCase()

  useEffect(() => {
    microsoftConversions.complete();
  });

  const ClickFeed = process.env.REACT_APP_ENABLE_GROWTHLINK_INSURANCE_INTEGRATION ? <GrowthlinkClickFeed /> : null;

  const ClickFeedMobile = (
    <Col id="complete-clickfeed-mobile">
      {ClickFeed}
    </Col>
  );

  const ClickFeedDesktop = (
    <Row id="complete-clickfeed-desktop">
      <Col lg={6} md={12} sm={12} className="" />
      <Col lg={6} md={12} sm={12} className="">
        {ClickFeed}
      </Col>
    </Row>
  );

  return (
    <Container>
      <Row id="complete-summary-container">
        <Col
          lg={6}
          md={12}
          sm={12}
          className="d-flex flex-column justify-content-start align-items-center"
          id="complete-summary-confirmation"
        >
          <h3 className="pt-1 text-center">
            You’re all set
            {' '}
            <span className="uppercase">{person.firstName.value}</span>
            !
          </h3>
          <h3 className="pt-4 text-center">
            We will process your payment within one business day.
          </h3>
          {process.env.REACT_APP_USE_BRAINTREE === "true" ? (
            <h5 className="py-3 text-center">
              Confirmation Code:
              {' '}
              {result.payment.paymentReference.toUpperCase()}
            </h5>
          ) : null}
        </Col>
        {isMobile ? ClickFeedMobile : null}
        <Col lg={6} md={12} sm={12} className="py-1">
          <CheckoutSummary
            text={`${general.location.id}`}
            violations={selectedViolations}
            violationsTotal={violations.totalSelectedFines}
            serviceFee={violations.serviceFeeAmount}
            creditCardFeePercentage={violations.creditCardFeePercentage}
            creditCardFee={violations.creditCardFeeAmount}
            totalFees={violations.totalFees}
            total={violations.orderTotal}
            totalAdditionalTicketFee={violations.totalAdditionalTicketFee}
          />
          <GoogleConversionTracking
            onSuccess={() => {}}
            onError={err => console.log("GC", err)}
            remarketingOnly={false}
            conversionLabel={ADWORDS_LABELS[city].complete}
          />
        </Col>
      </Row>
      {isMobile ? null : ClickFeedDesktop}
    </Container>
  );
};

const mapState = state => ({
  person: state.person,
  violations: state.violations,
  general: state.general,
  result: state.payment.result,
  isMobile: state.general.isMobile,
});

export default connect(mapState)(Complete);
