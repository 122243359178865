import axios from "axios";
import {
  calculateCreditCardFees,
  STEPS,
  calculateTotalFines,
  filterViolations,
  BACKEND_URL, visitorInfo,
} from "../helpers";
import {
  setStep,
  setFetchingStatus,
  // startOrderTimeout
} from "./index";
import {
  socketFetchTickets,
} from "../sockets/emitters";

import GoogleAnalytics from "../tracking/google";

/**
 * DEFAULT STATE
 */

const defaultState = {
  items: [],
  filterText: "",
  filterResults: [],
  allSelected: false,
  selected: {},
  totalSelectedFines: 0, // Total fine amount of selected tickets
  creditCardFeeAmount: 0, // calculated by multiplying
  orderTotal: 0, // total amount to be charged
  serviceFeeAmount: 0, // Flat amount to be added to total
  creditCardFeePercentage: 0, // to be multiplied as percentage of fines?
  additionalFeePerTicket: 0,
  totalAdditionalTicketFee: 0,
};

/**
 * ACTION TYPES
 */

const LOAD_VIOLATIONS_STATE = "LOAD_VIOLATIONS_STATE";
const SET_TOTAL_ADDITIONAL_TICKET_FEE = "SET_TOTAL_ADDITIONAL_TICKET_FEE"
const SET_ADDITIONAL_TICKET_FEE = "SET_ADDITIONAL_TICKET_FEE"
const SET_SERVICE_FEE_AMOUNT = "SET_SERVICE_FEE_AMOUNT";
const SET_CREDIT_CARD_FEE_PERCENTAGE = "SET_CREDIT_CARD_FEE_PERCENTAGE";
const SET_CREDIT_CARD_FEE_AMOUNT = "SET_CREDIT_CARD_FEE_AMOUNT"; // calculated fines * cc fee %
const RESET_VIOLATIONS = "RESET_VIOLATIONS";
const FETCH_VIOLATIONS = "FETCH_VIOLATIONS";
const SET_VIOLATIONS = "SET_VIOLATIONS";
const SELECT_VIOLATION = "SELECT_VIOLATIONS";
const CLEAR_SELECTED_VIOLATIONS = "CLEAR_SELECTED_VIOLATIONS";
const SET_ORDER_TOTAL = "SET_ORDER_TOTAL";
const SET_ALL_SELECTED = "SET_ALL_SELECTED";
const SET_VIOLATIONS_FILTER_TEXT = "SET_VIOLATIONS_FILTER_TEXT";
const SET_VIOLATIONS_FILTER_RESULTS = "SET_VIOLATIONS_FILTER_RESULTS";

/**
 * ACTION CREATORS
 */
export const loadViolationsState = state => ({ type: LOAD_VIOLATIONS_STATE, state });
export const resetViolations = () => ({ type: RESET_VIOLATIONS });
export const fetchViolations = params => ({ type: FETCH_VIOLATIONS, params });
export const setViolations = violations => ({
  type: SET_VIOLATIONS,
  violations,
});
export const selectViolation = violationId => ({
  type: SELECT_VIOLATION,
  violationId,
});
export const clearSelectedViolations = () => ({
  type: CLEAR_SELECTED_VIOLATIONS,
});
export const setOrderTotal = total => ({
  type: SET_ORDER_TOTAL,
  total,
});
export const setServiceFeeAmount = serviceFeeAmount => ({
  type: SET_SERVICE_FEE_AMOUNT,
  serviceFeeAmount,
});
export const setAdditionalTicketFee = additionalFeePerTicket => ({
  type: SET_ADDITIONAL_TICKET_FEE,
  additionalFeePerTicket,
});
export const setTotalAdditionalTicketFee = selectedTicketsCount => ({
  type: SET_TOTAL_ADDITIONAL_TICKET_FEE,
  selectedTicketsCount,
});
export const setCreditCardFeePercentage = creditCardFeePercentage => ({
  type: SET_CREDIT_CARD_FEE_PERCENTAGE,
  creditCardFeePercentage,
});
export const setAllSelected = allSelected => ({
  type: SET_ALL_SELECTED,
  allSelected,
});
export const setViolationsFilterText = text => ({
  type: SET_VIOLATIONS_FILTER_TEXT,
  text,
});
export const setViolationsFilterResults = resultsArr => ({
  type: SET_VIOLATIONS_FILTER_RESULTS,
  resultsArr,
});

export const setCreditCardFeeAmount = feeAmount => ({
  type: SET_CREDIT_CARD_FEE_AMOUNT,
  feeAmount,
});

/**
 * THUNK CREATORS
 */

export const loadViolationsPersistedState = (state) => dispatch => {
  return dispatch(loadViolationsState(state));
};

export const receiveFees = feesObject => (dispatch) => {
  dispatch(setCreditCardFeePercentage(feesObject.creditCard));
  dispatch(setServiceFeeAmount(feesObject.service));
  return dispatch(setAdditionalTicketFee(feesObject.additionalFeePerTicket));
};

export const receiveViolations = violations => (dispatch) => {
  dispatch(setFetchingStatus(false));
  dispatch(setStep(STEPS.result));
  return dispatch(setViolations(violations));
};

export const requestViolations = searchParamsObject => (dispatch, getState) => {
  // ***** SOCKET IMPLEMENTATION *****
  const state = getState();
  const location = state.general.location;
  switch (state.search.searchType) {
    case "ticketSearch":
      socketFetchTickets({ ...state.search.ticketSearch, location }, state.search.recaptchaToken);
      break;
    case "fullSearch":
      socketFetchTickets({ ...state.search.fullSearch, location }, state.search.recaptchaToken);
      break;
    default:
      break;
  }
}
  // // ***** REQUEST-VIOLATIONS BEFORE SOCKETS ******
  // const requestStartTime = moment();
  // const state = getState();
  // checkCookiesDisabled()
  //   .then((cookiesDisabled) => {
  //     // // console.log('cookiesDisabled 1', cookiesDisabled);

  //     if (cookiesDisabled) {
  //       dispatch(setFetchingStatus(false));
  //       return dispatch(setError({
  //         title: 'Cookies are required!',
  //         message: 'Please enable cookies in your web browser.',
  //       }));
  //     }

  //     const { location } = state.general;
  //     const { searchType } = state.search;
  //     const queryString = qs.stringify(searchParamsObject);
  //     const url = `${BACKEND_URL}/search/${location.name}/${searchType}?${queryString}`;

  //     return axios
  //       .get(url, { withCredentials: true })
  //       .then((result) => {
  //         dispatch(startOrderTimeout());
  //         const requestEndTime = moment();
  //         const elapsedRequestTime = requestEndTime.diff(requestStartTime);
  //         if (elapsedRequestTime < MINIMUM_SEARCH_FETCH_TIME) {
  //           return setTimeout(() => {
  //             dispatch(receiveFees(result.data.fees));
  //             return dispatch(receiveViolations(result.data.violations));
  //           }, MINIMUM_SEARCH_FETCH_TIME - elapsedRequestTime);
  //         }
  //         dispatch(receiveFees(result.data.fees));
  //         return dispatch(receiveViolations(result.data.violations));
  //       })
  //       .catch((error) => {
  //         const requestEndTime = moment();
  //         const elapsedRequestTime = requestEndTime.diff(requestStartTime);
  //         if (elapsedRequestTime < MINIMUM_SEARCH_FETCH_TIME) {
  //           return setTimeout(() => {
  //             dispatch(setFetchingStatus(false));
  //             const message = axiosError(error);
  //             return dispatch(setError(message));
  //           }, MINIMUM_SEARCH_FETCH_TIME - elapsedRequestTime);
  //         }
  //         dispatch(setFetchingStatus(false));
  //         const message = axiosError(error);
  //         return dispatch(setError(message));
  //       });
  //   });
// };

const checkIfAllSelected = () => (dispatch, getState) => {
  const state = getState().violations;
  const selectedIds = new Set(Object.keys(state.selected));
  let pointer = 0;
  let allValidSelected = true;
  while (allValidSelected && pointer < state.items.length) {
    const violation = state.items[pointer];
    if (violation.amount !== 0) {
      // check if is valid violation
      if (!selectedIds.has(violation.violationNumber)) allValidSelected = false;
    }
    pointer += 1;
  }
  return dispatch(setAllSelected(allValidSelected));
};

export const addOrRemoveViolation = violationId => (dispatch, getState) => {
  dispatch(selectViolation(violationId));
  dispatch(checkIfAllSelected());
  dispatch(setTotalAdditionalTicketFee())
  const {
    totalSelectedFines,
    creditCardFeePercentage,
    serviceFeeAmount,
    totalAdditionalTicketFee,
  } = getState().violations;
  const creditCardFeeAmount = calculateCreditCardFees(
    totalSelectedFines,
    creditCardFeePercentage
  );
  dispatch(setCreditCardFeeAmount(creditCardFeeAmount));
  const total = serviceFeeAmount + creditCardFeeAmount + totalAdditionalTicketFee + totalSelectedFines;
  return dispatch(setOrderTotal(total));
};

export const resetSelectedViolations = () => dispatch => {
  dispatch(clearSelectedViolations());
  return dispatch(setOrderTotal(0));
};

export const selectAllValidViolations = () => (dispatch, getState) => {
  const state = getState().violations;
  const selectedIds = Object.keys(state.selected);
  state.items.forEach(violation => {
    if (violation.amount !== 0 && selectedIds.indexOf(violation.violationNumber) === -1) {
      dispatch(addOrRemoveViolation(violation.violationNumber));
    }
  });
  return dispatch(setAllSelected(true));
};

export const toggleSelectAll = () => (dispatch, getState) => {
  const state = getState().violations;
  if (state.allSelected) {
    return dispatch(clearSelectedViolations());
  }
  return dispatch(selectAllValidViolations());
};

export const handleViolationsFilterChange = filterText => (
  dispatch,
  getState
) => {
  const cleanFilterText = filterText.trim();
  dispatch(setViolationsFilterText(cleanFilterText));
  if (cleanFilterText === "") return dispatch(setViolationsFilterResults([]));
  const state = getState().violations;
  const cleanCaseFilterText = cleanFilterText.toLowerCase();
  const violations = state.items;
  return filterViolations(cleanCaseFilterText, violations)
    .then(results => dispatch(setViolationsFilterResults(results)))
    .catch(error => console.log("error filtering results", error));
};

export const getTicketsByPlates = (plates) => async (dispatch) => {
  let violations = [];
  let fees = {};

  let results;
  try {
    results = await axios.post(`${BACKEND_URL}/search/chicago/me/unpaidTickets`, { plates });
  } catch (error) {
    console.error('Error getting tickets from /me/unpaid_tickets: ', error);
    return false;
  }

  if (results && results.data?.violations && results.data?.fees) {
    violations = results.data.violations; // Emulating the existing socket response from the backend
    fees = results.data.fees;
  }

  dispatch(receiveFees(fees));
  dispatch(receiveViolations(violations));
  dispatch(selectAllValidViolations());

  return true;
};

export const savePaymentDetails = (ckoPaymentId) => async (dispatch, getState) => {
  const state = getState();

  try {
    await axios.post(`${BACKEND_URL}/savePaymentDetails`, {
      ckoPaymentId: ckoPaymentId,
      visitorInfo: visitorInfo(),
      uploadedImageID: (state.payment.files.id.id) ? [
        state.payment.files.id.id,
      ] : null,
      city: state.general.location,
    });
  } catch (error) {
    console.error('Error while saving payment details: ', error);
    return false;
  }

  // if (results && results.data?.violations && results.data?.fees) {
  //   violations = results.data.violations; // Emulating the existing socket response from the backend
  //   fees = results.data.fees;
  // }
  //
  // dispatch(receiveFees(fees));
  // dispatch(receiveViolations(violations));
  // dispatch(selectAllValidViolations());

  return true;
};

/**
 * REDUCER
 */

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOAD_VIOLATIONS_STATE:
      return action.state;
    case RESET_VIOLATIONS:
      return defaultState;
    case SET_VIOLATIONS:
      return {
        ...state,
        items: [
          ...action.violations.sort(
            (a, b) => new Date(b.issueDate) - new Date(a.issueDate)
          )
        ]
      };
    case SELECT_VIOLATION: {
      const newSelected = { ...state.selected };
      if (newSelected[action.violationId]) {
        // If violation was already selected, then we need to remove it.
        delete newSelected[action.violationId];
        GoogleAnalytics.action.deselectTicket(action.amount);
      } else {
        // We need to find find the violation and place it in selected object.
        let index = 0;
        let found = false; // short circuit while loop
        while (index < state.items.length && !found) {
          if (state.items[index].violationNumber === action.violationId) {
            found = true;
          } else {
            index += 1;
          }
        }
        newSelected[action.violationId] = state.items[index];
        GoogleAnalytics.action.selectTicket(action.amount);
      }
      // Calculate a total amount for the violations
      const newTotal = calculateTotalFines(newSelected);
      return { ...state, selected: newSelected, totalSelectedFines: newTotal };
    }
    case CLEAR_SELECTED_VIOLATIONS:
      return { ...state, selected: {}, allSelected: false };
    case SET_ORDER_TOTAL: {
      return { ...state, orderTotal: action.total };
    }
    case SET_ALL_SELECTED:
      return { ...state, allSelected: action.allSelected };
    case SET_VIOLATIONS_FILTER_TEXT:
      return { ...state, filterText: action.text };
    case SET_VIOLATIONS_FILTER_RESULTS:
      return { ...state, filterResults: action.resultsArr };
    case SET_TOTAL_ADDITIONAL_TICKET_FEE:
      const selectedTicketsCount = Object.keys(state.selected).length
      return {
        ...state,
        totalAdditionalTicketFee: (selectedTicketsCount - 1) * state.additionalFeePerTicket
      };
    case SET_ADDITIONAL_TICKET_FEE:
      return {
        ...state,
        additionalFeePerTicket: parseFloat(action.additionalFeePerTicket)
      };
    case SET_SERVICE_FEE_AMOUNT:
      return {
        ...state,
        serviceFeeAmount: parseFloat(action.serviceFeeAmount)
      };
    case SET_CREDIT_CARD_FEE_PERCENTAGE:
      return {
        ...state,
        creditCardFeePercentage: parseFloat(action.creditCardFeePercentage)
      };
    case SET_CREDIT_CARD_FEE_AMOUNT:
      return { ...state, creditCardFeeAmount: action.feeAmount };
    default:
      return state;
  }
}
