import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { attemptSearch, handleSearchChange, setSearchType } from "../store";

import { SEARCH_TYPES } from "../helpers/constants";
import { FullSearchForm, TicketIdSearchForm } from "../components/";

const RenderSearchForm = ({
  searchType,
  search,
  handleChange,
  handleSubmit,
}) => {
  const props = {
    handleChange,
    handleSubmit,
    disabled: search.fetching,
  };
  let SearchForm = FullSearchForm;
  switch (searchType) {
    default:
    case SEARCH_TYPES.full:
      props.lastName = search[SEARCH_TYPES.full].lastName;
      props.plateNumber = search[SEARCH_TYPES.full].plateNumber;
      props.plateState = search[SEARCH_TYPES.full].plateState;
      SearchForm = FullSearchForm;
      break;
    case SEARCH_TYPES.ticket:
      props.ticketId = search[SEARCH_TYPES.ticket].ticketId;
      SearchForm = TicketIdSearchForm;
      break;
  }
  return (<SearchForm {...props} />);
};

RenderSearchForm.propTypes = {
  search: PropTypes.shape({
    [SEARCH_TYPES.full]: PropTypes.object,
    [SEARCH_TYPES.ticket]: PropTypes.object,
    fetching: PropTypes.bool,
  }).isRequired,
  searchType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapState = state => ({
  search: state.search,
  searchType: state.search.searchType,
});

const mapDispatch = dispatch => ({
  changeSearchType: searchType => dispatch(setSearchType(searchType)),
  handleSubmit: (e) => {
    e.preventDefault();
    return dispatch(attemptSearch(SEARCH_TYPES.full));
  },
  handleChange: (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    return dispatch(handleSearchChange(fieldName, fieldValue));
  },
});

export default connect(
  mapState,
  mapDispatch,
)(RenderSearchForm);
