import React from 'react';
import { connect } from "react-redux";
import { Col, Fade, Row } from "reactstrap";

import {
  Frames,
  CardNumber,
  ExpiryDate,
  Cvv,
} from 'frames-react';

import {
  submitOrder,
  paymentReadyStatusChange,
  cardValidityStatusChange,
} from "../../../store";

import "./CkoDropin.css";
import BillingAddressLine1 from '../billingAddressLine1';
import BillingAddressLine2 from '../billingAddressLine2';
import BillingAddressZip from '../billingAddressZip';
import BillingAddressCity from '../billingAddressCity';
import BillingAddressState from '../billingAddressState';
import BillingAddressCountry from '../billingAddressCountry';

const CheckoutDropin = ({ setIsCardValid, submit, setPaymentReady, paymentReady, payment }) => {
  const handleCardTokenized = async (event) => {
    await submit(event.token,event.scheme)
  };

  const handleCardTokenizationFailed = (event) => {
    console.error('Card tokenization failed.', event);
  };

  const handleReady = () => {
    setPaymentReady(true);
  };

  const handleCardValidationChanged = () => {
    setIsCardValid(Frames.isCardValid());
  };

  return (
    <div className="checkout-dropin-wrapper">
      <Fade in={paymentReady} className="checkout-dropin-fade">
        <Frames
          config={{
          debug: process.env.NODE_ENV === 'production' ? true : false,
          publicKey: process.env.REACT_APP_CKO_PUBLIC_KEY,
          localization: {
            cardNumberPlaceholder: 'Card number',
            expiryMonthPlaceholder: 'MM',
            expiryYearPlaceholder: 'YY',
            cvvPlaceholder: 'CVV',
          },
          style: { // https://www.checkout.com/docs/integrate/frames/frames-customization-guide
            base: {
              fontSize: '16px',
              border: "1px solid #ced4da",
              borderRadius: "4px",
              paddingLeft: "10px",
              color: "#495057",
            },
          },
          cardholder: {
            billingAddress: {
              addressLine1: payment.BillingAddressLine1,
              addressLine2: payment.BillingAddressLine2,
              zip: payment.BillingAddressZip,
              city: payment.BillingAddressCity,
              state: payment.BillingAddressState,
              country: payment.BillingAddressCountry,
            },
          },
        }}
          // https://www.checkout.com/docs/integrate/frames/frames-reference
          ready={handleReady}
          cardTokenized={handleCardTokenized}
          cardTokenizationFailed={handleCardTokenizationFailed}
          cardValidationChanged={handleCardValidationChanged}
        >
          <Row>
            <Col>
              <CardNumber style={{height: "40px"}}/>
            </Col>
          </Row>
          <div className="cko-dropin-field-spacer"/>
          <Row>
            <Col>
              <ExpiryDate style={{height: "40px"}}/>
            </Col>
            <Col>
              <Cvv style={{height: "40px"}}/>
            </Col>
          </Row>
          <div className="cko-dropin-field-spacer"/>
          {/* CKO/frames-react doesn't implement fields for billing address: */}
          <Row>
            <Col>
              <BillingAddressLine1 />
            </Col>
          </Row>
          <Row>
            <Col>
              <BillingAddressLine2 />
            </Col>
          </Row>
          <Row>
            <Col>
              <BillingAddressCity />
            </Col>
            <Col>
              <BillingAddressState />
            </Col>
          </Row>
          <Row>
            <Col>
              <BillingAddressZip />
            </Col>
            <Col>
              <BillingAddressCountry />
            </Col>
          </Row>
        </Frames>
      </Fade>
    </div>
  );
};

const mapState = state => ({
  payment: state.payment,
});

const mapDispatch = dispatch => ({
  submit: (token,cardScheme) => dispatch(submitOrder(token,cardScheme)),
  setPaymentReady: bool => dispatch(paymentReadyStatusChange(bool)),
  setIsCardValid: bool => dispatch(cardValidityStatusChange(bool)),
});

export default connect(mapState, mapDispatch)(CheckoutDropin);
