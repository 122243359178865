import React from "react";

import "./searchDescription.css";

import { SEARCH_TYPES, PLATE_STATES } from "../helpers";

const SearchDescriptionString = props => {
  const { search } = props;
  switch (search.searchType) {
    case SEARCH_TYPES.full:
      return (
        <div className="search-description">
          {search[SEARCH_TYPES.full].lastName}{" "}
          {search[SEARCH_TYPES.full].plateNumber}
          {` `}
          {PLATE_STATES[search[SEARCH_TYPES.full].plateState]}
        </div>
      );
    case SEARCH_TYPES.ticket:
      return (
        <div className="search-description">
          {search[SEARCH_TYPES.ticket].ticketId}
        </div>
      );
    default:
      return <div />;
  }
};
export default SearchDescriptionString;
