import moment from "moment";
import { resetSearch, resetPayment, resetViolations } from "./index";
import {
  STEPS,
  CITIES,
  ORDER_TIME_LIMIT,
  WARNING_TIME,
  visitorInfo,
  checkCookiesDisabled,
  getCity
} from "../helpers";

import GoogleAnalytics from "../tracking/google";

const MOBILE_MENU_WIDTH = 800;

const city = getCity()

const defaultState = {
  location: CITIES[city], // city to search and pay in
  error: false, // holds an error to be displayed
  step: STEPS.search, // Current step in funnel
  width: window.innerWidth,
  isMobile: window.innerWidth <= MOBILE_MENU_WIDTH,
  showMenu: !(window.innerWidth <= MOBILE_MENU_WIDTH),
  showCookiesDisabled: false,
  showContact: false, // boolean for showing contact form
  showServices: false, // boolean for showing services modal
  showTerms: false,
  showPrivacyPolicy: false,
  orderTimeoutWarning: null, // holdtimeout
  orderTimeoutWarningToggle: false, // display warning order will timeout soon
  orderTimeout: null, // holds timeout
  orderTimeoutTime: 0, // time left before order end
  visitorInfo: visitorInfo(),
};

/**
 * ACTION TYPES
 */

const LOAD_GENERAL_STATE = "LOAD_GENERAL_STATE";
const SET_STEP = "SET_STEP";
const SET_ERROR = "SET_ERROR";
const CLEAR_ERROR = "CLEAR_ERROR";
const RESET_GENERAL = "RESET_GENERAL";
const SET_WIDTH = "SET_WIDTH";
const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU";
const TOGGLE_MODAL_VISIBILITY = "TOGGLE_MODAL_VISIBILITY";
const SET_ORDER_TIMEOUT = "SET_ORDER_TIMEOUT";
const SET_ORDER_TIMEOUT_TIME = "SET_ORDER_TIMEOUT_TIME";
const SET_ORDER_TIMEOUT_WARNING_TOGGLE = "SET_ORDER_TIMEOUT_WARNING_TOGGLE";
const SET_SHOW_COOKIES_DISABLED = "SET_SHOW_COOKIES_DISABLED";

/**
 * ACTION CREATORS
 */

export const loadGeneralState = state => ({ type: LOAD_GENERAL_STATE, state });
export const setStep = step => ({ type: SET_STEP, step });
export const setError = error => ({ type: SET_ERROR, error });
export const clearError = () => ({ type: CLEAR_ERROR });
export const resetGeneral = () => ({ type: RESET_GENERAL });
export const setWidth = width => ({ type: SET_WIDTH, width });
export const toggleMobileMenu = () => ({ type: TOGGLE_MOBILE_MENU });
export const toggleModalVisibility = modal => ({
  type: TOGGLE_MODAL_VISIBILITY,
  modal
});
export const setOrderTimeout = (timeoutHolder, warningHolder) => ({
  type: SET_ORDER_TIMEOUT,
  timeoutHolder,
  warningHolder
});
export const setOrderTimeoutTime = time => ({
  type: SET_ORDER_TIMEOUT_TIME,
  time
});
export const setOrderTimeoutWarningToggle = () => ({
  type: SET_ORDER_TIMEOUT_WARNING_TOGGLE
});
export const setShowCookiesDisabled = value => ({
  type: SET_SHOW_COOKIES_DISABLED,
  value
});

/**
 * THUNK CREATORS
 */

export const loadGeneralPersistedState = (state) => (dispatch) => {
  return dispatch(loadGeneralState(state))
};

export const getWindowWidth = () => dispatch => {
  // eslint-disable-next-line
  const width = window.innerWidth
  return dispatch(setWidth(width));
};

export const resetEverything = () => (dispatch, getState) => {
  const state = getState().general;
  clearTimeout(state.orderTimeout);
  clearTimeout(state.orderTimeoutWarning);
  dispatch(resetSearch());
  dispatch(resetViolations());
  dispatch(resetPayment());
  return dispatch(resetGeneral());
};
export const startOrderTimeout = () => dispatch => {
  const now = moment();
  const timeoutTime = now.add(ORDER_TIME_LIMIT, "ms");
  dispatch(setOrderTimeoutTime(timeoutTime));
  const timeout = setTimeout(() => {
    dispatch(resetEverything());
  }, ORDER_TIME_LIMIT);
  const warning = setTimeout(() => {
    dispatch(setOrderTimeoutWarningToggle());
  }, WARNING_TIME);
  return dispatch(setOrderTimeout(timeout, warning));
};

export const cookieChecker = () => dispatch => {
  checkCookiesDisabled()
    .then(showCookiesDisabled => {
      // console.log('showCookiesDisabled 1', showCookiesDisabled)
      if (showCookiesDisabled) {
        GoogleAnalytics.action.cookiesDisabled();
      }
      dispatch(setShowCookiesDisabled(showCookiesDisabled));
    })
};
/**
 * REDUCER
 */

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOAD_GENERAL_STATE:
      return action.state;
    case SET_SHOW_COOKIES_DISABLED:
      return {
        ...state,
        showCookiesDisabled: action.value
      };
    case SET_ORDER_TIMEOUT:
      return {
        ...state,
        orderTimeout: action.timeoutHolder,
        orderTimeoutWarning: action.orderTimeoutWarning
      };
    case SET_ORDER_TIMEOUT_TIME:
      return { ...state, orderTimeoutTime: action.time };
    case SET_ORDER_TIMEOUT_WARNING_TOGGLE:
      return {
        ...state,
        orderTimeoutWarningToggle: !state.orderTimeoutWarningToggle
      };
    case SET_STEP:
      return { ...state, step: action.step };
    case SET_ERROR:
      return { ...state, error: action.error };
    case CLEAR_ERROR:
      return { ...state, error: false };
    case RESET_GENERAL:
      return defaultState;
    case SET_WIDTH:
      return {
        ...state,
        width: action.width,
        isMobile: action.width <= MOBILE_MENU_WIDTH,
        showMenu: !(window.innerWidth <= MOBILE_MENU_WIDTH)
      };
    case TOGGLE_MOBILE_MENU:
      return { ...state, showMenu: !state.showMenu };
    case TOGGLE_MODAL_VISIBILITY:
      if (state[action.modal] === false) {
        // means we are about to show it
        switch (action.modal) {
          case "showContact":
            GoogleAnalytics.view.contact();
            break;
          case "showServices":
            GoogleAnalytics.view.services();
            break;
          case "showTerms":
            GoogleAnalytics.view.terms();
            break;
          case "showPrivacyPolicy":
            GoogleAnalytics.view.privacy();
            break;
          default:
            break;
        }
      }
      return { ...state, [action.modal]: !state[action.modal] };

    default:
      return state;
  }
}
