import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { AES, enc } from "crypto-js"

import { STEPS } from "../helpers";
import Search from "./search";
import Violations from "./violations";
import Payment from "./payment";
import Complete from "./complete";
import history from "../history";

import GoogleAnalytics from "../tracking/google";
import getRouteQuery from "../helpers/getRouteQuery";
import logToServer from "../helpers/logToServer";

import { loadSearchPersistedState } from '../store/search';
import { loadPersonPersistedState } from '../store/person';
import { getTicketsByPlates, loadViolationsPersistedState } from '../store/violations';
import { savePaymentDetails, loadPaymentPersistedState, getCkoPaymentIdFromSessionId } from '../store/payment';
import { loadGeneralPersistedState } from '../store/general';
import { getFailureMessage, getPersistedApplicationState, setStep, setSubmitted } from "../store";

const changePath = target => {
  if (history.location.pathname !== target) {
    history.push(target);
  }
}

const STEP_COMPONENT = {
  [STEPS.search]: Search,
  [STEPS.result]: Violations,
  [STEPS.payment]: Payment,
  [STEPS.done]: Complete,
}

const STEP_ROUTE = {
  [STEPS.search]: "/search",
  [STEPS.result]: "/select",
  [STEPS.payment]: "/payment",
  [STEPS.done]: "/success?pc=", // query for insurance growthlink clickfeed - see comments in \src\components\clickFeed.js
}

const STEP_TRACKER = {
  [STEPS.search]: GoogleAnalytics.view.home,
  [STEPS.result]: GoogleAnalytics.view.ticketsList,
  [STEPS.payment]: GoogleAnalytics.view.paymentForm,
  [STEPS.done]: GoogleAnalytics.view.success,
}

const RenderSteps = ({
  step,
  getQueryTickets,
  goToPaymentSuccess,
  goToPayment,
  savePaymentDetails,
  setPaymentSubmitted ,
  getPaymentFailureMessage,
  loadGeneralPersistedState,
  loadPaymentPersistedState,
  loadPersonPersistedState,
  loadSearchPersistedState,
  loadViolationsPersistedState,
}) => {
  const location = useLocation();
  const query = getRouteQuery(location);

  if (query && query.plateNumbers) {
    const { plateNumbers } = query;
    const plateString = AES.decrypt(plateNumbers, process.env.REACT_APP_CRYPTOJS_SECRET).toString(enc.Utf8);

    let plates = [];
    if (plateString && plateString[0] === "[" && plateString.slice(-1) === "]") {
      const cleanedPlates = plateString.replace(/"/g, "").slice(1, -1); // remove the brackets + doublequotes CryptoJS puts there
      plates = cleanedPlates.split(",");
    }

    if (plates.length) {
      getQueryTickets(plates);
    }
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      const paymentFormResponse = query.paymentResult // Query paremeter on our URL set by CKO hosted payment page when it sends us back to this domain
      const ckoSessionId = query['cko-session-id']
      if (paymentFormResponse && ckoSessionId) {
        const ckoPaymentId = await getCkoPaymentIdFromSessionId(ckoSessionId)
        const persistedState = await getPersistedApplicationState(ckoPaymentId)
        if (!persistedState) return false

        const { general, payment, person, search, violations } = persistedState
        loadGeneralPersistedState(general)
        loadPaymentPersistedState(payment)
        loadPersonPersistedState(person)
        loadSearchPersistedState(search)
        loadViolationsPersistedState(violations)

        switch (paymentFormResponse) {
          case "success":
            // User had successful checkout on CKO hosted payment page
            goToPaymentSuccess();
            savePaymentDetails(ckoSessionId)
            break;
          case "cancel":
            // User cancelled on CKO hosted payment page, so pick up where we left off
            goToPayment();
            break;
          case "failure":
            // Payment failed on CKO api - go back to payment page & show the user the error
            getPaymentFailureMessage(ckoSessionId);
            goToPayment();
            setPaymentSubmitted(false);
            break;
          default:
            logToServer(`Received unexpected response from CKO hosted page: ${paymentFormResponse} - CKO Session ID: ${ckoSessionId}`, "warn")
        }
      }
    }
    asyncWrapper()
  }, [
    query,
    goToPaymentSuccess,
    goToPayment,
    savePaymentDetails,
    setPaymentSubmitted,
    getPaymentFailureMessage,
    loadGeneralPersistedState,
    loadPaymentPersistedState,
    loadPersonPersistedState,
    loadSearchPersistedState,
    loadViolationsPersistedState,
  ])

  useEffect(() => {
    window.scrollTo(0,0);
    changePath(STEP_ROUTE[step]);
    if(STEP_TRACKER[step]) STEP_TRACKER[step]()
  }, [step]);

  const Page = STEP_COMPONENT[step] || STEP_COMPONENT[STEPS.search];
  return <Page />
}

const mapState = state => ({
  step: state.general.step,
});

const mapDispatch = (dispatch) => ({
  getQueryTickets: (plates) => dispatch(getTicketsByPlates(plates)),
  savePaymentDetails: (ckoSessionId) => dispatch(savePaymentDetails(ckoSessionId)),
  goToPaymentSuccess: () => dispatch(setStep(STEPS.done)),
  goToPayment: () => dispatch(setStep(STEPS.payment)),
  setPaymentSubmitted: (bool) => dispatch(setSubmitted(bool)),
  getPaymentFailureMessage: (sessionID) => dispatch(getFailureMessage(sessionID)),
  loadGeneralPersistedState: (state) => dispatch(loadGeneralPersistedState(state)),
  loadPaymentPersistedState: (state) => dispatch(loadPaymentPersistedState(state)),
  loadPersonPersistedState: (state) => dispatch(loadPersonPersistedState(state)),
  loadSearchPersistedState: (state) => dispatch(loadSearchPersistedState(state)),
  loadViolationsPersistedState: (state) => dispatch(loadViolationsPersistedState(state)),
});

export default connect(mapState, mapDispatch)(RenderSteps);
