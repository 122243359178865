import React from "react";
import {connect} from "react-redux";
import {
  CITY_SEARCH_TYPES,
  SEARCH_TYPES_SEARCH_BY_TEXT
} from "../helpers/constants";
import { SectionTitle } from "./index";

const searchTypes = ({ currentSearchType, changeSearchType, location }) => {
  const citySearchTypes = CITY_SEARCH_TYPES[location.name.toLowerCase()]
  const searchTypes = []
  for (let citySearchType in citySearchTypes) {
    const isActive = currentSearchType === citySearchTypes[citySearchType];
    searchTypes.push(
      <SectionTitle
        onClick={() => changeSearchType(citySearchTypes[citySearchType])}
        key={citySearchType}
        active={isActive}
        clickable={!isActive}
        padRight
      >
        {SEARCH_TYPES_SEARCH_BY_TEXT[citySearchTypes[citySearchType]]}
      </SectionTitle>
    );
  }
  return searchTypes
}

const SearchFormSelector = (props) => {
  return (
    <div className="d-inline-flex">
      {searchTypes(props)}
    </div>
  )
}
const mapState = state => ({
  location: state.general.location
});

export default connect(mapState)(SearchFormSelector);
