import { checkIfCanSubmit, setDropIn } from "./index";
import { validate } from "../helpers";

/**
 * DEFAULT STATE
 */
const defaultState = {
  firstName: { value: "", error: "", dirty: false },
  lastName: { value: "", error: "", dirty: false },
  phoneNumber: { value: "", error: "", dirty: false },
  email: { value: "", error: "", dirty: false },
  emailConfirm: { value: "", error: "", dirty: false, disabled: true },
};

/**
 * ACTION TYPES
 */

const LOAD_PERSON_STATE = "LOAD_PERSON_STATE";
const SET_PERSON_FIELD_VALUE = "SET_PERSON_FIELD_VALUE";
const SET_PERSON_FIELD_DIRTY = "SET_PERSON_FIELD_DIRTY";
const SET_PERSON_FIELD_ERROR = "SET_PERSON_FIELD_ERROR";
const SET_PERSON_FIELD_DISABLED = "SET_PERSON_FIELD_DISABLED";

/**
 * ACTION CREATORS
 */

export const loadPersonState = state => ({ type: LOAD_PERSON_STATE, state });
export const setPersonFieldValue = (fieldName, fieldValue) => ({
  type: SET_PERSON_FIELD_VALUE,
  fieldName,
  fieldValue
});
export const setPersonFieldDirty = fieldName => ({
  type: SET_PERSON_FIELD_DIRTY,
  fieldName
});
export const setPersonFieldError = (fieldName, fieldError) => ({
  type: SET_PERSON_FIELD_ERROR,
  fieldName,
  fieldError
});
export const setPersonFieldDisabled = (fieldName, disabled) => ({
  type: SET_PERSON_FIELD_DISABLED,
  fieldName,
  disabled
});

/**
 * THUNKS
 */

export const loadPersonPersistedState = (state) => dispatch => {
  return dispatch(loadPersonState(state));
};

export const onBlurPersonFieldValidation = fieldName => (
  dispatch,
  getState
) => {
  const fieldValue = getState().person[fieldName].value;
  switch (fieldName) {
    case "firstName":
      if (fieldValue.length <= 2) {
        dispatch(
          setPersonFieldError(
            fieldName,
            "First name must be at least three characters long."
          )
        );
      } else {
        dispatch(setPersonFieldError(fieldName, ""));
      }
      break;
    case "lastName":
      if (fieldValue.length <= 2) {
        dispatch(
          setPersonFieldError(
            fieldName,
            "Last name must be at least three characters long."
          )
        );
      } else {
        dispatch(setPersonFieldError(fieldName, ""));
      }
      break;
    default:
      break;
  }
  return dispatch(checkIfCanSubmit());
};

export const onChangePersonFieldValidation = (fieldName, fieldValue) => (
  dispatch,
  getState
) => {
  const field = getState().person[fieldName];
  switch (fieldName) {
    case "firstName":
      if (field.dirty) {
        if (fieldValue.length <= 2) {
          dispatch(
            setPersonFieldError(
              fieldName,
              "First name must be at least two characters long."
            )
          );
        } else if (validate.isNumeric(fieldValue)) {
          dispatch(
            setPersonFieldError(
              fieldName,
              "First name can not be only numbers."
            )
          );
        } else {
          dispatch(setPersonFieldError(fieldName, ""));
        }
      }
      break;
    case "lastName":
      if (field.dirty) {
        if (fieldValue.length <= 2) {
          dispatch(
            setPersonFieldError(
              fieldName,
              "Last name must be at least two characters long."
            )
          );
        } else if (validate.isNumeric(fieldValue)) {
          dispatch(setPersonFieldError(fieldName, "Last name can not be only numbers."));
        } else {
          dispatch(setPersonFieldError(fieldName, ""));
        }
      }
      break;
    case "phoneNumber":
      {
        const isValidPhoneNumber = validate.phoneNumber(fieldValue);
        if (!isValidPhoneNumber) {
          dispatch(setPersonFieldError(fieldName, "Not a valid phone number."));
        } else {
          dispatch(setPersonFieldError(fieldName, ""));
        }
      }
      break;
    case "email":
      {
        const isValidEmail = validate.email(fieldValue);
        if (!isValidEmail) {
          dispatch(setPersonFieldError(fieldName, "Not a valid email."));
          dispatch(setPersonFieldDisabled("emailConfirm", true));
        } else {
          dispatch(setPersonFieldError(fieldName, ""));
          dispatch(setPersonFieldDisabled("emailConfirm", false));
          // lets compare to the emailConfirm field
          const emailConfirm = getState().person.emailConfirm.value;
          dispatch(onChangePersonFieldValidation("emailConfirm", emailConfirm));
        }
      }
      break;
    case "emailConfirm":
      {
        const email = getState().person.email.value.toLowerCase();
        if (email !== fieldValue.toLowerCase()) {
          dispatch(setPersonFieldError(fieldName, "Email must be identical."));
          if (process.env.REACT_APP_USE_BRAINTREE === 'true')
            dispatch(setDropIn(false));
        } else {
          dispatch(setPersonFieldError(fieldName, ""));
          if (process.env.REACT_APP_USE_BRAINTREE === 'true')
            dispatch(setDropIn(true));
        }
      }
      break;
    default:
      break;
  }
  return dispatch(checkIfCanSubmit());
};

export const setPersonField = (fieldName, fieldValue) => dispatch => {
  dispatch(setPersonFieldValue(fieldName, fieldValue));
  dispatch(onChangePersonFieldValidation(fieldName, fieldValue));
  return dispatch(checkIfCanSubmit());
};

/**
 * REDUCER
 */

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOAD_PERSON_STATE:
      return action.state;
    case SET_PERSON_FIELD_VALUE: {
      const newState = { ...state };
      newState[action.fieldName].value = action.fieldValue;
      return newState;
    }
    case SET_PERSON_FIELD_DIRTY: {
      const newState = { ...state };
      newState[action.fieldName].dirty = true;
      return newState;
    }
    case SET_PERSON_FIELD_ERROR: {
      const newState = { ...state };
      newState[action.fieldName].error = action.fieldError;
      return newState;
    }
    case SET_PERSON_FIELD_DISABLED: {
      const newState = { ...state };
      newState[action.fieldName].disabled = action.disabled;
      return newState;
    }
    default:
      return state;
  }
}
