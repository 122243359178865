import { SEARCH_TYPES, CITIES, checkCookiesDisabled, getCity } from "../helpers";
import { setError, clearError, requestViolations, cookieChecker } from "./index";
import GoogleAnalytics from "../tracking/google";
/**
 * DEFAULT STATE
 */
const defaultState = {
  searchType: SEARCH_TYPES.full, // by plate or ticket id
  recaptchaToken: null, // google recaptcha && must be sent to backend
  fetching: false // fetching violations
};
const currentCity = getCity()

if (currentCity === 'philadelphia') {
  defaultState.searchType = SEARCH_TYPES.full
}

defaultState[SEARCH_TYPES.full] = {
  lastName: "",
  plateNumber: "",
  plateState: CITIES[currentCity].state,
};
defaultState[SEARCH_TYPES.ticket] = {
  ticketId: ""
};

/**
 * ACTION TYPES
 */

const LOAD_SEARCH_STATE = "LOAD_SEARCH_STATE";
const SET_SEARCH_FIELD = "SET_SEARCH_FIELD";
const SET_FETCHING_STATUS = "SET_FETCHING_STATUS";
const SET_RECAPTCHA_TOKEN = "SET_RECAPTCHA_TOKEN";
const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
const RESET_SEARCH = "RESET_SEARCH";

/**
 * ACTION CREATORS
 */
export const loadSearchState = state => ({ type: LOAD_SEARCH_STATE, state });
export const setSearchField = (searchType, fieldName, fieldValue) => ({
  type: SET_SEARCH_FIELD,
  searchType,
  fieldName,
  fieldValue
});
export const setFetchingStatus = fetchingBoolean => ({
  type: SET_FETCHING_STATUS,
  fetchingBoolean
});
export const setRecaptchaToken = recaptchaToken => ({
  type: SET_RECAPTCHA_TOKEN,
  recaptchaToken
});
export const setSearchType = searchType => ({
  type: SET_SEARCH_TYPE,
  searchType
});
export const resetSearch = () => ({ type: RESET_SEARCH });

/**
 * THUNK CREATORS
 */

export const loadSearchPersistedState = (state) => dispatch => {
  return dispatch(loadSearchState(state));
};

const validationError = (title, message) => dispatch => {
  dispatch(setFetchingStatus(false));
  dispatch(setRecaptchaToken(null));
  return dispatch(setError({ title, message }));
};

export const handleSearchChange = (fieldName, fieldValue) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { searchType } = state.search;
  return dispatch(setSearchField(searchType, fieldName, fieldValue));
};

export const submitSearch = () => (dispatch, getState) => {
  dispatch(clearError());
  const state = getState();
  const { searchType } = state.search;
  const search = state.search[searchType];
  const city = state.general.location

  // trim here
  // eslint-disable-next-line
  for (const key in search) {
    // eslint-disable-next-line
    if (search.hasOwnProperty(key)) {
      if (key === "plateNumber" || key === "ticketId") {
        search[key] = search[key].trim().replace(/\s+/g, "");
      } else {
        search[key] = search[key].trim();
      }
      dispatch(handleSearchChange(key, search[key]));
    }
  }

  dispatch(setFetchingStatus(true));

  GoogleAnalytics.action.searchTickets(searchType);

  // form validation
  const title = "Missing Required Detail";
  if (searchType === SEARCH_TYPES.full) {
    // We keep lastName/businessName validation for Chicago only.
    if (city === 'chicago' && search.lastName.length < 2) {
      const message = `A last name or business name must be 2 or more characters long.`;
      GoogleAnalytics.action.searchValidation(message);
      return dispatch(validationError(title, message));
    }
    if (search.plateNumber.length < 4) {
      const message = `A license plate number must be 4 or more characters long.`;
      GoogleAnalytics.action.searchValidation(message);
      return dispatch(validationError(title, message));
    }
    if (!search.plateState.length) {
      const message = "Please select the state that issued your license plate.";
      GoogleAnalytics.action.searchValidation(message);
      return dispatch(validationError(title, message));
    }
  }
  // if theres no captcha token and one is required block search
  if ((!state.search.recaptchaToken || !state.search.recaptchaToken.length) && process.env.REACT_APP_RECAPTCHA_SITE_ID) {
    return dispatch(validationError(
      "Captcha required",
      "Please check the captcha box to perform the search."
    ));
  }
  // We keep ticketId validation for Chicago only.
  if (
    city.name === 'Chicago' &&
    searchType === SEARCH_TYPES.ticket &&
    (isNaN(search.ticketId) || //eslint-disable-line
      search.ticketId.length !== 10)
  ) {
    const message = "A valid ticket number is exactly 10 digits long.";
    GoogleAnalytics.action.searchValidation(message);
    return dispatch(validationError(title, message));
  }
  if (
    searchType === SEARCH_TYPES.ticket &&
    !isNaN(search.ticketId) && //eslint-disable-line
    search.ticketId.length === 10
  ) {
    dispatch(handleSearchChange("ticketId", search.ticketId.trim()));
  }

  return dispatch(requestViolations(search));
};

export const attemptSearch = () => dispatch => {
  checkCookiesDisabled()
    .then(cookiesDisabled => {
      if (cookiesDisabled) {
        return dispatch(cookieChecker());
      }
      return dispatch(submitSearch());
    })

};

/**
 * REDUCER
 */

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOAD_SEARCH_STATE:
      return action.state;
    case SET_SEARCH_FIELD: {
      const newState = { ...state };
      newState[action.searchType][action.fieldName] = action.fieldValue;
      return newState;
    }
    case SET_FETCHING_STATUS:
      return { ...state, fetching: action.fetchingBoolean };
    case SET_RECAPTCHA_TOKEN:
      return { ...state, recaptchaToken: action.recaptchaToken };
    case SET_SEARCH_TYPE:
      return { ...state, searchType: action.searchType };
    case RESET_SEARCH:
      return defaultState;
    default:
      return state;
  }
}
